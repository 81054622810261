import React from 'react'
import styled from 'styled-components'
import media from '../css/media'
import Btn from './button'

import illustration_caixa_preta_desktop from '../../images/illustration_caixa_preta_desktop.svg'
import illustration_caixa_preta_mobile from '../../images/illustration_caixa_preta_mobile.svg'

import { useIntl } from 'gatsby-plugin-intl'

const NotificationImg = styled.div`
  margin: 20px auto;
  text-align: center;
`

const NotificationDesktopImg = styled.div`
  display: none;
  ${media.tablet`
    display: block;
  `}
`

const NotificationMobileImg = styled.div`
  ${media.tablet`
    display: none;
  `}
`

const NotificationMsg = styled.p`
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
`

const NotificationBtn = styled.div`
  text-align: center;
`

const Notification = ({ message, onBtnClick }) => {
  const intl = useIntl()

  return (
    <div>
      <NotificationImg>
        <NotificationDesktopImg>
          <img src={illustration_caixa_preta_desktop} width='306px' alt='' />
        </NotificationDesktopImg>
        <NotificationMobileImg>
          <img src={illustration_caixa_preta_mobile} width='200px' alt='' />
        </NotificationMobileImg>
      </NotificationImg>
      <NotificationMsg>{message}</NotificationMsg>
      <NotificationBtn>
        <Btn
          text={intl.formatMessage({ id: 'common.gotIt' })}
          bgcolor='#31c27c'
          padding='.32rem 1rem'
          bordercolor='#31c27c'
          fontSize='.85em'
          margin='.25rem 0 0 0'
          onClick={onBtnClick}
        />
      </NotificationBtn>
    </div>
  )
}

export default Notification
