import React from 'react'
import styled from 'styled-components'

import toast_info from '../../images/toast_info.svg'
import toast_error from '../../images/toast_error.svg'
import toast_success from '../../images/toast_success.svg'
import media from '../css/media'

const Toast = styled.div`
  box-shadow: none;
  opacity: 1;
  display: flex;
  padding: 0 1rem;
  gap: 20px;
  align-items: center;
  font-size: 0.8em;

  ${media.desktop`
        font-size: .9em;
    `};
`

const Icon = styled.div`
  width: 5%;
`

const Msg = styled.div`
  width: 95%;
  flex: 1;
`

const ToastMessage = (props) => {
  const getIcon = (icon) => {
    switch (icon) {
      case 'info':
        return toast_info
      case 'success':
        return toast_success
      case 'warn':
      case 'error':
        return toast_error
      default:
        return ''
    }
  }

  return (
    <Toast className={props.icon}>
      <Icon>
        <img src={getIcon(props.icon)} width={30} height={30} alt='' />
      </Icon>
      <Msg>{props.message}</Msg>
    </Toast>
  )
}

export default ToastMessage
