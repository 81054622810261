import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'

import media from '../../css/media'

import { EnvConfig } from '../../../config/EnvConfig'
import Error from '../../common/errorMessage'

import {
  PaymentProviders,
  PaymentStatus,
  ModalTypes,
  PaymentTypes,
} from '../../../utils/constants'

import { usePaymentsContext } from '../../../context/paymentProvider'
import { useModalContext } from '../../../context/modalProvider'

import storageService from '../../../services/storageService'

import {
  getPay4FunDepositUrl,
  getPay4FunGoDepositUrl,
  getDirectaDepositUrl,
  getInovapayGatewayDepositUrl,
  getInovapayWalletDepositUrl,
  getGigadatDepositUrl,
} from '../../../adapters/payments'
import InovapayWallet from './inovapayWallet'
import { getUserLimits } from '../../../adapters/user'
import { AuthContext } from '../../../context/authProvider'
import toast from '../../common/toast'
import FormatAmount from '../../common/formatAmount'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Header = styled.div.attrs((props) => ({
  margin: props.margin || 'initial',
}))`
  font-weight: 600;
  font-size: 1.4em;
  line-height: 2;
  margin: ${(props) => props.margin};
`

const Content = styled.div`
  width: 100%;
  padding: 1rem;

  small {
    padding: 0.5rem 0;
    text-align: center;
    display: block;
  }

  ${media.desktop`
        width: 450px;
        margin: 0 auto;
        padding: 0;
    `};
`

const Row = styled.div`
  display: flex;
  flex-direction: column;

  ${media.desktop`
        flex-direction: row;
    `};
`

const InfoText = styled.div`
  padding: 1rem 0 0 0;
`

const Col = styled.div`
  flex: 1 1 100%;
  width: 100%;

  ${media.desktop`
        flex: 1 1 50%;
        width: 50%;
    `};
`

const AmountWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, minmax(0, 33.33%));
  margin: 0 0 1rem 0;
`

const Amount = styled.div`
  background-color: #eee;
  text-align: center;
  color: #000;
  padding: 0.8rem 0;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.9em;

  &.active {
    background-color: #31c27c;
    color: #fff;
  }
`

const Method = ({ depositClose }) => {
  const user = storageService.getUser()

  let paymentDetails = null
  const intl = useIntl()
  const { getUserWallet } = useContext(AuthContext)
  const { currentMethod } = usePaymentsContext()
  const { open } = useModalContext()

  const [error, setError] = useState(false)
  const [paymentInProgress, setPaymentInProgress] = useState(false)
  const [depositDisabled, setDepositDisabled] = useState(false)
  const [depositAmount, setDepositAmount] = useState('')
  const [userLimits, setUserLimits] = useState([])

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    setValue,
  } = useForm()

  useEffect(() => {
    if (currentMethod && paymentDetails) {
      /**
       * TODO, depositAmount here is of type Number
       * while minimum_amount and maximum_amount are of type String
       * we should avoid comparations like below
       */
      const overLimits =
        depositAmount < paymentDetails.minimum_amount ||
        depositAmount > paymentDetails.maximum_amount

      setDepositDisabled(depositAmount <= 0 || overLimits)
    }
  }, [depositAmount, setDepositDisabled, currentMethod, paymentDetails])

  useEffect(() => {
    setError(false)
  }, [depositAmount])

  useEffect(() => {
    if (paymentDetails) {
      const depositAmount = parseInt(paymentDetails.default_amounts[0].amount)
      setDepositAmount(depositAmount)
      setValue('depositAmount', depositAmount)
    }
  }, [paymentDetails, setValue])

  useEffect(() => {
    const fetchData = async () => {
      const response = await getUserLimits()
      if (response.ok) setUserLimits(response.data.data)
    }
    fetchData()
  }, [])

  useEffect(() => {
    const onPaymentFailed = (e) => {
      try {
        if (typeof e.data === 'string') {
          const data = JSON.parse(e.data)
          if (data && data.action === PaymentStatus.ERROR) {
            toast.error(intl.formatMessage({ id: 'payments.receipt.error' }))
          }

          if (data && data.action === PaymentStatus.SUCCESS) {
            toast.success(
              intl.formatMessage({ id: 'payments.deposit.success' })
            )

            depositClose()
            open({
              title: intl.formatMessage({ id: 'payments.receipt.deposit' }),
              data: data,
              type: ModalTypes.RECEIPT,
            })
          }
        }
      } catch (ex) {
        console.log('error occured', ex)
      }
    }

    window.addEventListener('message', onPaymentFailed)

    return () => {
      window.removeEventListener('message', onPaymentFailed)
    }
  }, [intl, open, depositClose])

  const onChange = (e) => {
    setDepositAmount(e.target.value === '' ? '' : parseInt(e.target.value))
  }

  const onDeposit = async (data) => {
    try {
      const code = currentMethod.payment_code.toUpperCase()

      let response = null
      let paymentRequested = false

      let origin = `${EnvConfig.API_URL}/payments/directa/response`
      switch (code) {
        case PaymentProviders.GIGADAT_INTERAC_CPI:
        case PaymentProviders.PAY_4_FUN:
        case PaymentProviders.PAY_4_FUN_GO_PIX:
        case PaymentProviders.PAY_4_FUN_GO_TI:
        case PaymentProviders.INOVAPAY_GATEWAY:
        case PaymentProviders.INOVAPAY_WALLET:
        case PaymentProviders.WEBPAY:
          origin = `${EnvConfig.SITE_URL}/${intl.locale}/payment-success/`
          break
        default:
          break
      }

      const commonQuery = `&type=DEPOSIT&provider=${currentMethod.payment_code}&amount=${depositAmount}&lang=${intl.locale}`
      const successUrl = `${origin}?status=success${commonQuery}&currency=${user.wallet.currency.short_code}&accountId=${user.id}`
      const errorUrl = `${origin}?status=error${commonQuery}`
      const backUrl = `${EnvConfig.SITE_URL}/${intl.locale}/payment-success/?status=reset`

      setPaymentInProgress(true)

      switch (code) {
        case PaymentProviders.GIGADAT_INTERAC_CPI:
          paymentRequested = true
          response = await getGigadatDepositUrl({
            amount: depositAmount,
            site: `${EnvConfig.SITE_URL}`,
            payment_method: 'CPI',
          })

          break
        case PaymentProviders.PAY_4_FUN:
          paymentRequested = true
          response = await getPay4FunDepositUrl({
            ok_url: successUrl,
            not_ok_url: errorUrl,
            amount: depositAmount,
          })

          break
        case PaymentProviders.PAY_4_FUN_GO_PIX:
        case PaymentProviders.PAY_4_FUN_GO_TI:
          paymentRequested = true
          response = await getPay4FunGoDepositUrl({
            ok_url: successUrl,
            not_ok_url: errorUrl,
            amount: depositAmount,
            payment_method:
              code === PaymentProviders.PAY_4_FUN_GO_PIX
                ? 'PIX'
                : 'BankTransfer',
            payment_methodId: null,
          })

          break
        case PaymentProviders.INOVAPAY_GATEWAY:
          paymentRequested = true
          response = await getInovapayGatewayDepositUrl({
            amount: depositAmount,
          })

          break
        case PaymentProviders.INOVAPAY_WALLET:
          paymentRequested = true
          response = await getInovapayWalletDepositUrl({
            amount: depositAmount,
            user_login: data.userLogin,
            user_secure_id: data.userSecureId,
          })

          break
        default:
          break
      }

      if (Object.values(PaymentProviders).includes(code) && !paymentRequested) {
        paymentRequested = true
        response = await getDirectaDepositUrl({
          value: depositAmount,
          back_url: backUrl,
          error_url: errorUrl,
          success_url: successUrl,
          payment_method: code.toUpperCase(),
        })
      }

      if (!paymentRequested) {
        setError('Payment not supported')
      }

      if (response) {
        if (response.ok) {
          const { payment_method, url: redirectionURL } = response.data.data

          if (!payment_method) {
            window.location.replace(redirectionURL)
          } else if (
            payment_method &&
            (payment_method.payment_provider ===
              PaymentProviders.INOVAPAY_GATEWAY ||
              payment_method.payment_provider ===
                PaymentProviders.INOVAPAY_WALLET)
          ) {
            depositClose()
            getUserWallet()
            open({
              title: intl.formatMessage({ id: 'payments.receipt.deposit' }),
              data: response.data.data,
              type: ModalTypes.RECEIPT,
            })

            if (typeof window !== 'undefined') {
              window.dataLayer.push({
                event: 'DepositConfirm',
                accountId: user.id,
                stake: depositAmount,
                currency: user.wallet.currency.short_code,
              })
            }
          }
        }

        if (!response.ok) {
          let message = response.error.message
          switch (response.error.messageCode) {
            case 147:
              {
                // Deposit limit exceeded
                const depositLimit = userLimits.find(
                  (l) => l.type === 'DEPOSIT'
                )
                const amount = intl.formatNumber(depositLimit.amount, {
                  style: 'currency',
                  currency: user.wallet.currency.short_code,
                })

                message = intl
                  .formatMessage({ id: 'payments.depositLimitExceeded' })
                  .replace('{}', depositLimit ? `${amount}` : '')
              }
              break
            default:
              break
          }
          setError(message)
        }

        paymentRequested = false
        setPaymentInProgress(false)
      }
    } catch (exc) {
      setPaymentInProgress(false)
      setError(exc.message)
    }
  }

  if (!currentMethod) return null

  if (
    !currentMethod.payment_details.some(
      (row) =>
        row.player_currency === user.wallet.currency.short_code &&
        row.type_of_payment === PaymentTypes.DEPOSIT
    )
  )
    return null

  paymentDetails = currentMethod.payment_details.filter(
    (row) =>
      row.player_currency === user.wallet.currency.short_code &&
      row.type_of_payment === PaymentTypes.DEPOSIT
  )[0]

  return (
    <Wrapper>
      <Content>
        <Header>
          <FormattedMessage id='payments.information' />
        </Header>
        <Row>
          <Col>
            <FormattedMessage id='payments.minimum' />
            :&nbsp;
            <FormatAmount
              amount={paymentDetails.minimum_amount}
              currency={user.wallet.currency.short_code}
              maximumFractionDigits='0'
              useGrouping={
                paymentDetails.minimum_amount.toString().length >= 5
                  ? true
                  : false
              }
            />
          </Col>
          <Col>
            <FormattedMessage id='payments.maximum' />
            :&nbsp;
            <FormatAmount
              amount={paymentDetails.maximum_amount}
              currency={user.wallet.currency.short_code}
              maximumFractionDigits='0'
              useGrouping={
                paymentDetails.maximum_amount.toString().length >= 5
                  ? true
                  : false
              }
            />
          </Col>
        </Row>

        {paymentDetails.customer_information_text.length > 0 && (
          <Row>
            <InfoText>{paymentDetails.customer_information_text}</InfoText>
          </Row>
        )}

        <Header margin='1rem 0 0 0'>
          <FormattedMessage id='payments.amount' />
        </Header>
        <AmountWrapper>
          {paymentDetails.default_amounts.map((row) => (
            <Amount
              key={parseInt(row.amount)}
              onClick={() => setDepositAmount(parseInt(row.amount))}
              className={parseInt(row.amount) === depositAmount ? 'active' : ''}
            >
              <FormatAmount
                amount={parseInt(row.amount)}
                currency={user.wallet.currency.short_code}
                maximumFractionDigits='0'
                useGrouping={
                  parseInt(row.amount).toString().length >= 5 ? true : false
                }
              />
            </Amount>
          ))}
        </AmountWrapper>

        <form onSubmit={handleSubmit(onDeposit)}>
          <label htmlFor='depositAmount'>
            <FormattedMessage id='payments.amount' />
          </label>

          <input
            type='number'
            value={depositAmount}
            min={paymentDetails.minimum_amount}
            max={paymentDetails.maximum_amount}
            className={`${errors.depositAmount ? 'invalid' : ''} ${
              !errors.depositAmount && touchedFields.depositAmount
                ? 'valid'
                : ''
            }`}
            {...register('depositAmount', {
              required: true,
            })}
            onChange={onChange}
          />

          {currentMethod.payment_code.toUpperCase() ===
            PaymentProviders.INOVAPAY_WALLET && (
            <InovapayWallet
              register={register}
              errors={errors}
              touchedFields={touchedFields}
            />
          )}

          <button
            type='submit'
            disabled={depositDisabled || paymentInProgress}
            className={`${
              depositDisabled || paymentInProgress ? 'disabled' : ''
            }`}
          >
            <FormattedMessage id='common.deposit' />
            &nbsp;
            <FormatAmount
              amount={depositAmount}
              currency={user.wallet.currency.short_code}
              maximumFractionDigits='0'
              useGrouping={depositAmount.toString().length >= 5 ? true : false}
            />
          </button>
        </form>

        {currentMethod.payment_code.toUpperCase() ===
          PaymentProviders.GIGADAT_INTERAC_CPI && (
          <small>® Trade-mark of Interac Corp. Used under license.</small>
        )}

        {error && <Error text={`${error}`} />}
      </Content>
    </Wrapper>
  )
}

export default Method
