import React from 'react'

const InteracAch = ({ register, errors, touchedFields }) => {
  const onInputChange = (e, max) => {
    let value = e.target.value
    if (e.target.value.length > max) {
      value = value.slice(0, max)
    }
    e.target.value = value.replace(/\D/g, '')
  }

  return (
    <>
      <div>
        <label htmlFor='fi'>Financial Institution Number</label>
        <input
          name='fi'
          className={`${errors.fi ? 'invalid' : ''} ${
            !errors.fi && touchedFields.fi ? 'valid' : ''
          }`}
          {...register('fi', {
            required: true,
          })}
          onChange={(e) => onInputChange(e, 3)}
        />
      </div>
      <div>
        <label htmlFor='transit'>Account Transit Number</label>
        <input
          name='transit'
          className={`${errors.transit ? 'invalid' : ''} ${
            !errors.transit && touchedFields.transit ? 'valid' : ''
          }`}
          {...register('transit', {
            required: true,
          })}
          onChange={(e) => onInputChange(e, 5)}
        />
      </div>
      <div>
        <label htmlFor='acct'>Account Number</label>
        <input
          name='acct'
          className={`${errors.acct ? 'invalid' : ''} ${
            !errors.acct && touchedFields.acct ? 'valid' : ''
          }`}
          {...register('acct', {
            required: true,
          })}
          onChange={(e) => onInputChange(e, 12)}
        />
      </div>
    </>
  )
}

export default InteracAch
