import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'

import MethodsCarousel from '../methodsCarousel'
import Method from './method'
import Btn from '../../common/button'

import { usePaymentsContext } from '../../../context/paymentProvider'
import { PaymentTypes } from '../../../utils/constants'
import storageService from '../../../services/storageService'
import { resendActivationEmail } from '../../../adapters/user'
import { EnvConfig } from '../../../config/EnvConfig'
import { getPendingWithdrawals } from '../../../adapters/payments'
import PendingWithdrawal from '../pendingWithdrawal'
import toast from '../../common/toast'

const Message = styled.div`
  text-align: center;
  padding: 2rem 0;
`

const Deposit = ({ onClose }) => {
  const intl = useIntl()
  const user = storageService.getUser()

  const [pendingWithdrawals, setPendingWithdrawals] = useState([])
  const [pendingWithdrawalsTotals, setPendingWithdrawalsTotals] = useState(null)
  const { currentMethod, isRollbackWithdrawalAllowed } = usePaymentsContext()

  const data = useStaticQuery(graphql`
    {
      paymentsApi {
        values {
          lang
          methods {
            enabled
            payment_code
            payment_name
            payment_details {
              maximum_amount
              minimum_amount
              player_currency
              type_of_payment
              country_of_registration
              order_number
              customer_information_text
              default_amounts {
                amount
              }
              logo {
                height
                width
                url
                alt
              }
            }
          }
        }
      }
    }
  `)

  const getDepositPayments = (details) => {
    return details.filter(
      (row) => row.type_of_payment === PaymentTypes.DEPOSIT
    )[0]
  }

  const proceedToDeposit = () => {
    setPendingWithdrawals([])
    setPendingWithdrawalsTotals(null)
  }

  const resendEmailActivation = async () => {
    const response = await resendActivationEmail(
      user.email,
      `${EnvConfig.SITE_URL}/activate/`
    )

    if (response.ok)
      toast.success(intl.formatMessage({ id: 'account.resendEmailSuccess' }))
  }

  const {
    paymentsApi: { values },
  } = data

  const dataExistForLocale = values.some((row) => row.lang === intl.locale)
  const dataExistForPage =
    dataExistForLocale &&
    values.filter((row) => row.lang === intl.locale)[0].methods.length > 0
  const methods = dataExistForPage
    ? values
        .filter((row) => row.lang === intl.locale)[0]
        .methods.filter(
          (row) => row.enabled && getDepositPayments(row.payment_details)
        )
    : []

  useEffect(() => {
    if (!isRollbackWithdrawalAllowed) return
    getPendingWithdrawals().then((res) => {
      setPendingWithdrawals(res.data.data.transactions.content)
      setPendingWithdrawalsTotals(res.data.data.totals)
    })
  }, [isRollbackWithdrawalAllowed])

  return (
    <>
      {methods.length > 0 &&
        pendingWithdrawals.length === 0 &&
        user.user_status.name.toLowerCase() !== 'pending' && (
          <MethodsCarousel methods={methods} />
        )}

      {currentMethod &&
        pendingWithdrawals.length === 0 &&
        user.user_status.name.toLowerCase() !== 'pending' && (
          <Method depositClose={onClose} />
        )}

      {pendingWithdrawals.length > 0 && isRollbackWithdrawalAllowed && (
        <PendingWithdrawal
          user={user}
          data={pendingWithdrawals}
          totals={pendingWithdrawalsTotals}
          depositClose={onClose}
          proceedToDeposit={proceedToDeposit}
        />
      )}

      {methods.length === 0 &&
        user.user_status.name.toLowerCase() !== 'pending' && (
          <Message>
            {intl
              .formatMessage({ id: 'account.paymentMethodsNotAvailable' })
              .replace('{0}', user.wallet.currency.short_code)}
          </Message>
        )}

      {(user.user_status.name.toLowerCase() === 'pending' ||
        !user.user_status.deposit_enabled) && (
        <Message>
          {intl.formatMessage({ id: 'account.not-verified' })}

          <Btn
            text={intl.formatMessage({ id: 'account.resend-email' })}
            bgcolor='#31c27c'
            padding='.32rem 1rem'
            bordercolor='#31c27c'
            fontSize='.85em'
            onClick={resendEmailActivation}
          />
        </Message>
      )}
    </>
  )
}

export default Deposit
