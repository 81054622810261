import React from 'react'
import styled from 'styled-components'

const ImgTag = styled.img`
  width: 80px;
  height: auto;
  vertical-align: middle;
  cursor: pointer;

  &.logo {
    margin: 0.1rem 0 0 0;
  }

  &.burger-menu,
  &.main-search {
    height: 30px;
    width: 30px;
  }

  @media (min-width: 1400px) {
    &.main-search {
      margin: 0 1.4rem 0 0;
      height: 34px;
      width: 34px;
    }
  }

  @media (min-width: 1600px) {
    &.logo {
      width: 111px;
      margin: 0 2rem 0 0;
    }

    &.burger-menu {
      display: none;
    }
  }
`

const Image = (props) => {
  const { menuItem, onClick } = props

  if (!menuItem || !menuItem.image) return null

  return (
    <ImgTag
      onClick={onClick}
      width={menuItem.image.width}
      height={menuItem.image.height}
      className={`${menuItem.className}`}
      loading='lazy'
      src={`${menuItem.image.url}`}
      alt={`${menuItem.image.alt}`}
    />
  )
}

export default Image
