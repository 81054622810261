import React, { useEffect, useState, useContext } from 'react'
import { useLocation } from '@reach/router'
import styled from 'styled-components'
import { Link } from 'gatsby'
// import TransitionLink from "gatsby-plugin-transition-link"

import media from '../css/media'

import { AuthContext } from '../../context/authProvider'

const CtaLink = styled(Link)`
  border: 2px solid #fff;
  border-radius: 4px;
  color: #fff;
  background-color: #000;
  letter-spacing: 2px;
  min-width: 50px;
  display: inline-block;
  text-transform: uppercase;
  padding: 0.32rem 0.4rem;
  text-align: center;
  font-size: 0.8em;
  text-decoration: none;
  font-weight: 400;

  ${media.desktop`
    font-size: .85em;
    padding: .32rem 1rem;
    line-height: 20px;
  `};

  &.primary {
    border-color: #da0000;
    background-color: #da0000;
  }

  &.cashback {
    border-radius: 36px;
    padding: 0.3rem 0.8rem 0.3rem 2.5rem;
    text-align: right;
    background: #343434 url(${(props) => props.img}) 2px no-repeat;
    background-size: 25px;
    border-color: #343434;
    margin: 0 1rem 0 0;
    text-transform: capitalize;
    letter-spacing: 0;
    font-size: 1em;
    min-width: initial;
  }
`

const Href = styled(Link)`
  text-transform: capitalize;
  margin: 0 1.25rem 0 0;
  color: #fff;
  font-size: 1em;
  line-height: 25px;
  display: inline-block;
  text-decoration: none;

  ${(props) => {
    if (props.img) {
      return `
        background-image: url(${props.img});
        background-size: 24px;
        background-position: left center;
        background-repeat: no-repeat;
        padding: 0 0 0 26px;
      `
    }
  }}

  &.active {
    color: #da0000;
  }

  &.mobile {
    padding: 0 0 0 30px;
  }

  ${media.desktop`
    &.mobile {
      background: none;
      padding: 0;
    }

    &.active {
      border-bottom: 1px solid #da0000;
    }
  `};
`

const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  height: 35px;
  align-items: center;

  ${media.desktop`
    height: auto;
  `};

  > img {
    width: 24px;
    height: 24px;

    &.mobile {
      padding: 0 10px 0 0;
    }

    ${media.desktop`
      height: auto;

      &.mobile {
        display: none;
      }
    `};
  }
`

const NavLink = (props) => {
  const { menuItem } = props

  const location = useLocation()
  const { isLoggedIn } = useContext(AuthContext)

  const [activeTab, setActiveTab] = useState('')

  // const exitAnimation = (exit) => {
  //   exit.node.parentNode.style = `
  //     overflow: hidden;
  //     max-witdh: 100vw;
  //     max-height: 100vh;
  //   `
  // }
  // const entryAnimation = (entry) => {
  //   setTimeout(() => {
  //     entry.node.parentNode.removeAttribute("style");
  //   }, 500) // a little higher than exitConfig.length
  // }

  // const exitConfig = {
  //   trigger: exitAnimation,
  //   length: 0.36
  // }

  // const entryConfig = {
  //   trigger: entryAnimation,
  //   length: 0.46,
  //   delay: 0.12
  // }

  useEffect(() => {
    if (menuItem.link.url.indexOf(props.locale) > 0) {
      menuItem.link.url = menuItem.link.url.replace(`/${props.locale}/`, '/')
    }

    const url = `/${props.locale}${menuItem.link.url}`
    if (location.pathname === url) {
      setActiveTab(menuItem.name)
    }
  }, [
    location,
    setActiveTab,
    menuItem.link.url,
    menuItem.name,
    menuItem.link,
    props.locale,
  ])

  const setNavUrl = (menu) => {
    if (isLoggedIn && menu.link_logged_in.url !== '') {
      return `/${props.locale}${menu.link_logged_in.url}`
    }
    return `/${props.locale}${menu.link.url}`
  }

  return menuItem.className.indexOf('cta') >= 0 ? (
    <CtaLink
      to={setNavUrl(menuItem)}
      img={menuItem.image.url}
      title={`${menuItem.link.title}`}
      className={`${menuItem.className} ${menuItem.show_icon}`}
    >
      {menuItem.text}
    </CtaLink>
  ) : (
    <Row className='nav-link-row'>
      {menuItem.image.url && (
        <img
          loading='lazy'
          className={`${menuItem.show_icon}`}
          src={`${menuItem.image.url}`}
          alt={`${menuItem.image.alt}`}
          width={menuItem.image.width}
          height={menuItem.image.height}
        />
      )}
      <Href
        to={setNavUrl(menuItem)}
        title={`${menuItem.link.title}`}
        className={`${menuItem.className} ${
          menuItem.name === activeTab ? 'active' : ''
        }`}
      >
        {menuItem.text}
      </Href>
    </Row>
  )
}

export default NavLink
