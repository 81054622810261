import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import { useLocation } from '@reach/router'

import Image from './image'
import NavLink from './navLink'
import UserBalance from './userBalance'
import { AuthContext } from '../../context/authProvider'
import useModal from '../../hooks/useModal'
import Modal from '../common/modal'
import Search from '../common/search'
import SideMenu from './sideMenu'
import media from '../css/media'

import SearchIcon from '../../images/icon_search_black.svg'
import TimesIcon from '../../images/icon_times_white.svg'

const Middle = styled.div`
  flex: 0 0 20%;
  width: 20%;
  text-align: center;
`

const Left = styled.div`
  flex: 0 0 40%;
  width: 40%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`

const Right = styled.div`
  flex: 0 0 40%;
  width: 40%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;

  > img {
    width: 30px;
    height: 30px;
  }

  .cta {
    &:last-child {
      margin: 0 0 0 0.5rem;
    }
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 0;
  background: transparent;
  font-weight: 600;
`

const SearchInput = styled.input.attrs({ type: 'text' })`
  width: 80%;
  height: 36px;
  border-radius: 20px;
  border: 0;
  outline: none;
  padding: 0 0.75rem 0 2.25rem;
  letter-spacing: -0.14px;
  background: #fff url(${SearchIcon}) 5px no-repeat;
  background-size: 30px;
  margin: -3px auto;

  ${media.tablet`
    width: 600px;
  `};
`

const MobileNav = (props) => {
  const { data, locale } = props

  const breakpoint = 600

  const intl = useIntl()
  const location = useLocation()

  const [isShown, setSideMenuOpen] = useState(false)
  const { isLoggedIn } = useContext(AuthContext)
  const { openModal, closeModal, isOpen } = useModal()
  const [searchTerm, setSearchTerm] = useState('')
  const [width, setWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 768
  )

  const handleChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const onResizeWindow = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResizeWindow)
    }

    return () => {
      window.removeEventListener('resize', onResizeWindow)
    }
  })

  const logo = data.some((m) => m.className === 'logo')
    ? data.filter((m) => m.className === 'logo')[0]
    : null
  const cta = data.some((m) => m.className.indexOf('cta mobile') >= 0)
    ? data.filter((m) => m.className.indexOf('cta mobile') >= 0)
    : null
  const burgerMenu = data.some((m) => m.className === 'burger-menu')
    ? data.filter((m) => m.className === 'burger-menu')[0]
    : null
  const mainSearch = data.some((m) => m.className === 'main-search')
    ? data.filter((m) => m.className === 'main-search')[0]
    : null
  const cashbackCta = data.some((m) => m.className === 'cta cashback')
    ? data.filter((m) => m.className === 'cta cashback')[0]
    : null

  const setNavUrl = (menu) => {
    if (isLoggedIn && menu.link_logged_in.url !== '') {
      return `/${intl.locale}${menu.link_logged_in.url}`
    }
    return `/${intl.locale}${menu.link.url}`
  }

  return (
    <>
      {!isOpen && (
        <>
          <Left>
            <Image
              onClick={() => setSideMenuOpen(!isShown)}
              menuItem={burgerMenu}
            />
            <Image onClick={openModal} menuItem={mainSearch} />
          </Left>
          <Middle>
            {logo && (
              <Link title={logo.link.title} to={setNavUrl(logo)}>
                <Image menuItem={logo} />
              </Link>
            )}
          </Middle>
          <Right>
            {!isLoggedIn &&
              width > breakpoint &&
              cta.map((menuItem, i) => (
                <NavLink key={i} locale={locale} menuItem={menuItem} />
              ))}
            {!isLoggedIn &&
              width <= breakpoint &&
              location.pathname.indexOf(cta[1].link.url) < 0 &&
              location.pathname.indexOf(cta[0].link.url) < 0 && (
                <NavLink locale={locale} menuItem={cta[0]} />
              )}
            {!isLoggedIn &&
              width <= breakpoint &&
              location.pathname.indexOf(cta[1].link.url) > 0 && (
                <NavLink locale={locale} menuItem={cta[0]} />
              )}
            {!isLoggedIn &&
              width <= breakpoint &&
              location.pathname.indexOf(cta[0].link.url) > 0 && (
                <NavLink locale={locale} menuItem={cta[1]} />
              )}
            {isLoggedIn && <UserBalance cashbackCta={cashbackCta} />}
          </Right>
        </>
      )}

      {isOpen && (
        <SearchInput
          placeholder={intl.formatMessage({
            id: 'navigation.searchPlaceholder',
          })}
          value={searchTerm}
          onChange={handleChange}
        />
      )}

      {isOpen && (
        <CloseButton onClick={closeModal}>
          <img src={TimesIcon} alt='' width={20} height={20} />
        </CloseButton>
      )}

      {isOpen && (
        <Modal theme='search' isOpen={isOpen} onClose={closeModal}>
          <Search locale={locale} query={searchTerm} />
        </Modal>
      )}

      <SideMenu
        isShown={isShown}
        menu={data}
        setSideMenuOpen={setSideMenuOpen}
      />
    </>
  )
}

export default MobileNav
