import { createGlobalStyle } from 'styled-components'

import reset from './reset'
import media from './media'

import error_icon from '../../images/error_icon.svg'
import success_icon from '../../images/success_icon.svg'
import icon_arrow_down from '../../images/icon_arrow_down.svg'

const GlobalStyle = createGlobalStyle`
  ${reset}

  body {
    background-color: #1e1e1e;
    font-family: Barlow, sans-serif;
    font-size: 18px;
    font-display: swap;

    &.overflow-hidden {
      overflow: hidden;
    }
  }

  h1,
  h2 {
    font-weight: 900;
  }

  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
  }

  button {
    font-family: Barlow, sans-serif;
    font-display: swap;
    line-height: 1;
    cursor: pointer;
  }

  form {
    label {
      margin: 0 0 .2rem 0;
      display: block;
      font-family: Barlow, sans-serif;
      font-display: swap;
    }

    small {
      color: #9b9b9b;
      margin: 0 0 1rem 0;
      display: block;
    }

    p {
      text-align: center;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-align: center;
      font-weight: 600;
      font-size: 1.5em;
    }

    button {
      cursor: pointer;
      background-color: #31c27c;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 2px;
      border-color: #31c27c;
      margin: 2rem 0 0 0;
      appearance: button;
      border-radius: 6px;
      padding: 0 .75rem;
      font-size: 1em;
      line-height: 45px;
      display: block;
      background-clip: padding-box;
      width: 100%;
      box-sizing: border-box;
      font-family: Barlow, sans-serif;
      font-display: swap;
  
      &.secondary {
        background-color: #fff;
        border-color: #000;
        color: #000;
        width: auto;
        padding: 0 2rem;
      }
  
      &.disabled {
        background-color: #dbdbdb;
        border-color: #dbdbdb;
        color: #b4b4b4;
        opacity: .65;
        cursor: initial;
      }
    }

    ${media.desktop`
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 2em;
      }
    `};
  }

  input,
  select {
    border-radius: 6px;
    background-color: #eee;
    padding: 0 .75rem;
    font-size: 1em;
    border: 2px solid #eee;
    line-height: 45px;
    color: #000;
    display: block;
    background-clip: padding-box;
    margin: 0 0 1rem 0;
    width: 100%;
    box-sizing: border-box;
    font-family: Barlow, sans-serif;
    font-display: swap;

    &:disabled {
      color: darkgrey;
    }

    &:focus {
      background-color: #fff;
      color: #000;
    }

    &:focus-visible {
      outline: none;
    }

    &.invalid {
      background-color: #fff;
      border-color: #ff8282;
      background-image: url('${error_icon}');
      background-repeat: no-repeat;
      background-position: right 10px center;
      background-size: 20px;
    }

    &.valid {
      background-color: #fff;

      &:focus {
        border-color: #31c27c;
        background-image: url('${success_icon}');
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 20px;
      }
    }
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type="password"] {
    &.invalid {
      background: none;
    }

    &.valid {
      &:focus {
        background: none;
      }
    }
  }

  input[type="submit"],
  input[type="button"] {
    cursor: pointer;
    background-color: #31c27c;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-color: #31c27c;
    margin: 2rem 0 0 0;
    appearance: button;

    &.secondary {
      background-color: #fff;
      border-color: #000;
      color: #000;
      width: auto;
      padding: 0 2rem;
    }

    &.disabled {
      background-color: #dbdbdb;
      border-color: #dbdbdb;
      color: #b4b4b4;
      opacity: .65;
      cursor: initial;
    }
  }

  select {
    height: 48px;
    appearance: none;
    background: #eee url(${icon_arrow_down}) no-repeat right .5rem center;
  }

  .ReactModal__Overlay {
    background-color: rgba(0,0,0,0.5) !important;
  }

  .i-kb-search {
    display: none !important;
  }

  .Toastify__toast-container {
    width: 100%;
    z-index: 99999999;

    .Toastify__toast-body,
    .Toastify__toast {
      padding: 0;
    }

    .Toastify__toast {
      height: 50px;
      min-height: 50px;

      &.info {
        background-color: #fef0b1;
        color: #000;
  
        .toast {
          max-width: initial;
          background-color: #fef0b1;
          color: #000;
        }
      }
  
      &.error {
        background-color: #FF8282;
        color: #000;
  
        .toast {
          background-color: #FF8282;
          color: #000;
        }
      }
  
      &.success {
        background-color: #31C27C;
        color: #fff;
  
        .toast {
          background-color: #31C27C;
          color: #fff;
        }
      }
  
      &.warn {
        background-color: #FFE690;
        color: #000;
  
        .toast {
          background-color: #FFE690;
          color: #000;
        }
      }
    }

    .Toastify__toast-body {
      > div {
        width: 100%;
      }
    }

    .Toastify__close-button,
    .Toastify__progress-bar {
      display: none;
    }
  }
  .tl-wrapper-status--exiting {
    transition: 0.34s;
    opacity: 0 !important;
    transform: scale(1.05)
    transform-origin: 50vw 50vh
  }
  .tl-wrapper-status--entering {
    opacity: 0 !important;
  }
  .tl-wrapper-status--entered {
    opacity: 1;
    transition: 0.44s;
  }

  ${media.desktop`
    .Toastify__toast-container {
      width: 520px;
    }
    
    .i-kb-search {
      display: block !important;
    }
  `};

  div[id*="b_7zydrxo4"] > div,
  div[id*="b_a9mu547i"] > div,
  div[id*="b_w904ehqw"] > div,
  div[id*="b_i3wud71s"] > div,
  div[id*="b_c2ms0dsd"] > div,
  div[id*="b_e7no1e0a"] > div,
  div[id*="b_rp7clpxf"] > div {
    left: 0px !important;
    right: initial !important;
  }

  ${media.desktop`
    div[id*="b_7zydrxo4"] > div,
    div[id*="b_a9mu547i"] > div,
    div[id*="b_w904ehqw"] > div,
    div[id*="b_i3wud71s"] > div,
    div[id*="b_c2ms0dsd"] > div,
    div[id*="b_e7no1e0a"] > div,
    div[id*="b_rp7clpxf"] > div {
      right: 20px !important;
      left: initial !important;
    }
  `};
`

export default GlobalStyle
