import { coreApi } from './base'

export const getPay4FunDepositUrl = async (model) => {
  try {
    const response = await coreApi.post('/payments/deposit/pay4fun/url', model)
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getPay4FunGoDepositUrl = async (model) => {
  try {
    const response = await coreApi.post(
      '/payments/deposit/pay4fungo/url',
      model
    )
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getDirectaDepositUrl = async (model) => {
  try {
    const response = await coreApi.post('/payments/deposit/directa/url', model)
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getInovapayWalletDepositUrl = async (model) => {
  try {
    const response = await coreApi.post(
      '/payments/deposit/inovapay/wallet',
      model
    )
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getInovapayGatewayDepositUrl = async (model) => {
  try {
    const response = await coreApi.post(
      '/payments/deposit/inovapay/gateway/url',
      model
    )
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getGigadatDepositUrl = async (model) => {
  try {
    const response = await coreApi.post('/gigadat/deposit/request', model)
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const requestDirectaWithdrawal = async (model) => {
  try {
    const response = await coreApi.post(
      '/payments/withdrawal/directa/request',
      model
    )
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const requestPay4FunWithdrawal = async (model) => {
  try {
    const response = await coreApi.post(
      '/payments/withdrawal/pay4fun/request',
      model
    )
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const requestGigadatWithdrawal = async (model) => {
  try {
    const response = await coreApi.post('/gigadat/withdrawal/request', model)
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const requestInovapayGatewayWithdrawal = async (model) => {
  try {
    const response = await coreApi.post(
      '/payments/withdrawal/inovapay/gateway/request',
      model
    )
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const requestInovapayWithdrawal = async (model) => {
  try {
    const response = await coreApi.post(
      '/payments/withdrawal/inovapay/wallet/request',
      model
    )
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getDirectaBanks = async () => {
  try {
    const response = await coreApi.get('/payments/directa/banks')
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getPendingWithdrawals = async () => {
  try {
    const response = await coreApi.get(
      '/payments/pending-withdrawals?pageNumber=0&pageSize=100'
    )
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const cancelWithdrawals = async (ids) => {
  try {
    const response = await coreApi.delete('/payments/pending-withdrawals', {
      data: { transaction_ids: ids },
    })
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}
