import React, { useEffect, useContext, useState } from 'react'
import { useLocation } from '@reach/router'
import styled, { css } from 'styled-components'
import { useIntl } from 'gatsby-plugin-intl'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'

import Image from './image'
import NavLink from './navLink'
import { AuthContext } from '../../context/authProvider'
import SideMenuAccount from './sideMenuAccount'
import arrow_down_icon from '../../images/arrow_down_icon.svg'

const SideMenuWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  overflow-x: hidden;
  top: 0;
  left: 0;
  z-index: 6;
  display: none;

  ${(props) =>
    props.isShown
      ? css`
          display: block;
        `
      : ``};
`

const Menu = styled.div`
  background-color: #000000;
  color: #ffffff;
  width: calc(320px - 2rem);
  height: 100%;
  overflow-y: auto;
  transition: all 0.5s ease;
  animation: slide-out 0.5s forwards;
  transform: translateX(-100%);
  padding: 0 1rem;
  text-align: center;

  ${(props) =>
    props.isShown
      ? css`
          transform: translateX(0%);
          animation: slide-in 0.5s forwards;
        `
      : ``};

  > img {
    &.logo {
      text-align: center;
      margin: 2.5rem 0 2rem 0;
    }
  }

  a.cashback {
    text-align: left;
    border: none;
    padding: 0 0 0 33px;
    background-position-x: 3px;
    background-size: 19px;
    background-color: unset;
    line-height: 30px;
  }
`

const Row = styled.div`
  display: flex;
  gap: 20px;
  margin: 0 0 2rem 0;

  > a {
    flex: 0 1 50%;
    line-height: 20px;

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
      &:not(:last-child) {
        margin-right: 5%;
      }
    }
  }
`

const Col = styled.div`
  display: flex;
  flex-flow: column;
  margin: 2rem 0 0 0;

  .nav-link-row {
    height: 35px;

    img {
      height: 24px;

      &.mobile {
        display: block;
      }
    }

    a {
      text-align: left;
      line-height: 24px;

      &.active {
        border-bottom: none;
      }
    }
  }
`

const BlogNavigator = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const BlogArrow = styled.div`
  background: url(${arrow_down_icon}) 50% no-repeat;
  width: 20px;
  height: 16px;
  cursor: pointer;
  margin-top: 10px;

  > svg {
    fill: white;
  }

  &.open {
    transform: rotate(180deg);
  }
`

const BlogCategoryLink = styled(Link)`
  line-height: 1.87em;
  text-align: left;
  margin-left: 1.75em;
  display: block;
  color: white;
  text-decoration: none;

  &:hover {
    color: white;
    text-decoration: underline;
  }
`

const SideMenu = (props) => {
  const data = useStaticQuery(graphql`
    {
      menuApi {
        values {
          lang
          blogCategoryLinks {
            name
            text
            className
            link {
              url
              title
            }
          }
        }
      }
    }
  `)

  const {
    menuApi: { values },
  } = data

  const { isShown, menu, setSideMenuOpen } = props
  const intl = useIntl()
  const location = useLocation()
  const { isLoggedIn } = useContext(AuthContext)
  const [isBlogMenuOpen, setIsBlogMenuOpen] = useState(false)
  const blogCategoryLinks = values.find((row) => row.lang === intl.locale)
    .blogCategoryLinks

  useEffect(() => {
    setSideMenuOpen(false)
  }, [location, setSideMenuOpen])

  return (
    <SideMenuWrapper
      isShown={isShown}
      onClick={(e) => {
        /**
         * Prevent closing Side Menu
         * when blog icon is either collapsed or expanded
         */
        if (e.target.id !== 'toggleBlogMenu') {
          setSideMenuOpen(false)
        }
      }}
    >
      {isLoggedIn && (
        <>
          <SideMenuAccount />
        </>
      )}

      <Menu isShown={isShown}>
        {!isLoggedIn && (
          <>
            <Image menuItem={menu.filter((m) => m.className === 'logo')[0]} />
            <Row>
              {menu
                .filter((m) => m.className.indexOf('cta mobile') >= 0)
                .map((item, i) => {
                  return (
                    <NavLink locale={intl.locale} key={i} menuItem={item} />
                  )
                })}
            </Row>
          </>
        )}

        <Col>
          {menu
            .filter(
              (m) =>
                (m.type.toLowerCase() === 'text' &&
                  m.className.indexOf('cta') < 0) ||
                m.name.toLowerCase() === 'cashback'
            )
            .map((menuItem, index) => {
              return menuItem.name === 'Blog' ? (
                <div key={index}>
                  <BlogNavigator>
                    <NavLink
                      locale={intl.locale}
                      key={`menu-${index}`}
                      menuItem={menuItem}
                    />
                    <BlogArrow
                      id='toggleBlogMenu'
                      onClick={() => {
                        setIsBlogMenuOpen(!isBlogMenuOpen)
                      }}
                      className={`${isBlogMenuOpen ? 'open' : 'closed'}`}
                    />
                  </BlogNavigator>

                  {blogCategoryLinks &&
                    isBlogMenuOpen &&
                    blogCategoryLinks.map((blogItem, index) => {
                      return (
                        <BlogCategoryLink
                          key={`blog-${index}`}
                          to={`/${intl.locale}/blog${blogItem.link.url}`}
                        >
                          {blogItem.text}
                        </BlogCategoryLink>
                      )
                    })}
                </div>
              ) : (
                <NavLink locale={intl.locale} key={index} menuItem={menuItem} />
              )
            })}
        </Col>
      </Menu>
    </SideMenuWrapper>
  )
}

SideMenu.defaultProps = {
  isShown: false,
  menu: [],
}

SideMenu.propTypes = {
  isShown: PropTypes.bool,
  menu: PropTypes.array,
}

export default SideMenu
