import React, { useContext } from 'react'
import styled from 'styled-components'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import icon_nav_deposit from '../../images/icon_nav_deposit.svg'
import icon_nav_withdraw from '../../images/icon_nav_withdraw.svg'
import icon_nav_vault2 from '../../images/icon_nav_vault2.svg'
import icon_nav_logout from '../../images/icon_nav_logout.svg'
import icon_nav_oldbets from '../../images/icon_nav_oldbets.svg'
import icon_nav_account from '../../images/icon_nav_account.svg'

import storageService from '../../services/storageService'
import { navigate } from 'gatsby'
import { AuthContext } from '../../context/authProvider'
import FormatAmount from '../common/formatAmount'

const Nav = styled.div`
  width: 240px;
  min-height: 200px;
  right: 45px;
  top: 55px;
  border-radius: 5px;
  box-shadow: 0 3px 4px 0 rgb(0 0 0 / 20%);
  position: absolute;
  background-color: #fff;
  color: #212529;
  z-index: 1;

  &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #fff;
    border-width: 8px;
    margin-left: -8px;
  }
`

const Row = styled.div`
  display: flex;
  border-top: 1px solid #ddd;
  padding: 0.25rem 0.7rem;
  cursor: pointer;

  &:hover {
    background-color: #f8f9fa;
  }
`

const Icon = styled.div.attrs((props) => ({
  icon: props.icon || '',
}))`
  width: 20%;
  flex: 1 0 20%;
  background: url(${(props) => props.icon}) left center no-repeat;
  background-size: 30px;
`

const Text = styled.div`
  width: 80%;
  flex: 1 0 80%;
`

const CashbackAmount = styled.div`
  padding: 1rem 0.7rem;
  font-size: 0.9em;
  line-height: 18px;
  font-weight: 600;
`

const DropDownMenu = ({
  cashbackCta,
  openDeposit,
  openWithdraw,
  profileSlug,
}) => {
  const user = storageService.getUser()
  const intl = useIntl()
  const { logoutUser } = useContext(AuthContext)

  return (
    <Nav>
      <CashbackAmount>
        <FormattedMessage id='navigation.vault' />
        <br />
        <FormatAmount
          amount={user.wallet.vault_balance}
          currency={user.wallet.currency.short_code}
        />
      </CashbackAmount>
      <Row onClick={openDeposit}>
        <Icon icon={icon_nav_deposit} />
        <Text>
          <FormattedMessage id='navigation.deposit' />
        </Text>
      </Row>
      <Row onClick={openWithdraw}>
        <Icon icon={icon_nav_withdraw} />
        <Text>
          <FormattedMessage id='navigation.withdraw' />
        </Text>
      </Row>
      <Row onClick={() => navigate(`/${intl.locale}${cashbackCta.link.url}`)}>
        <Icon icon={icon_nav_vault2} />
        <Text>
          <FormattedMessage id='navigation.vault' />
        </Text>
      </Row>
      <Row onClick={() => navigate(`/${intl.locale}/old-bets/`)}>
        <Icon icon={icon_nav_oldbets} />
        <Text>
          <FormattedMessage id='navigation.oldBets' />
        </Text>
      </Row>
      <Row onClick={() => navigate(`/${intl.locale}/${profileSlug}/`)}>
        <Icon icon={icon_nav_account} />
        <Text>
          <FormattedMessage id='navigation.account' />
        </Text>
      </Row>
      <Row onClick={logoutUser}>
        <Icon icon={icon_nav_logout} />
        <Text>
          <FormattedMessage id='navigation.logout' />
        </Text>
      </Row>
    </Nav>
  )
}

export default DropDownMenu
