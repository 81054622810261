import React, { useEffect, useRef } from 'react'

import storageService from '../../services/storageService'

const LiveAgentScript = () => {
  const prevScrollY = useRef()

  useEffect(() => {
    if (typeof window === 'undefined') return

    let scriptAdded = false

    const handleScroll = () => {
      const currentScrollY = window.scrollY
      if (prevScrollY.current < currentScrollY) {
        if (!scriptAdded) {
          scriptAdded = true
          console.log('LiveAgent added')

          const lang =
            window.localStorage.getItem('gatsby-intl-language') || 'en'
          let group = ''

          switch (lang) {
            case 'fi':
              group = 'i3wud71s'
              break
            case 'no':
              group = 'c2ms0dsd'
              break
            case 'pt':
              group = 'a9mu547i'
              break
            case 'de':
              group = 'rp7clpxf'
              break
            case 'pe':
              group = 'w904ehqw'
              break
            case 'ca':
              group = 'e7no1e0a'
              break
            default:
              group = '7zydrxo4'
              break
          }

          const script = document.createElement('script')
          script.src = 'https://mobiltcs.ladesk.com/scripts/track.js'
          script.defer = true
          script.id = 'la_x2s6df8d'

          script.onload = script.onreadystatechange = function () {
            const rs = this.readyState

            if (rs && rs !== 'complete' && rs !== 'loaded') {
              return
            }

            const user = storageService.getUser()
            if (user) {
              window.LiveAgent.setUserDetails(
                user.email || '',
                user.first_name || '',
                user.last_name || ''
              )
            }

            window.chatButton = window.LiveAgent.createButton(group, this)
          }

          document.body.appendChild(script)
        }
      }

      prevScrollY.current = currentScrollY
    }

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return <></>
}

export default LiveAgentScript
