export const Countries = {
  ARGENTINA: 11,
  BRAZIL: 31,
  CHILE: 47,
  COLOMBIA: 51,
  GHANA: 85,
  INDIA: 104,
  KENYA: 118,
  MEXICO: 144,
  CANADA: 42,
  NIGERIA: 162,
  PERU: 175,
  URUGUAY: 240,
  CHINA: 48,
  JAPAN: 114,
}

export const Regions = {
  31: [
    {
      name: 'Acre',
    },
    {
      name: 'Alagoas',
    },
    {
      name: 'Amapa',
    },
    {
      name: 'Amazonas',
    },
    {
      name: 'Bahia',
    },
    {
      name: 'Ceara',
    },
    {
      name: 'Distrito Federal',
    },
    {
      name: 'Espirito Santo',
    },
    {
      name: 'Goias',
    },
    {
      name: 'Maranhao',
    },
    {
      name: 'Mato Grosso',
    },
    {
      name: 'Mato Grosso do Sul',
    },
    {
      name: 'Minas Gerais',
    },
    {
      name: 'Para',
    },
    {
      name: 'Paraiba',
    },
    {
      name: 'Parana',
    },
    {
      name: 'Pernambuco',
    },
    {
      name: 'Piaui',
    },
    {
      name: 'Rio de Janeiro',
    },
    {
      name: 'Rio Grande do Norte',
    },
    {
      name: 'Rio Grande do Sul',
    },
    {
      name: 'Rondonia',
    },
    {
      name: 'Roraima',
    },
    {
      name: 'Santa Catarina',
    },
    {
      name: 'Sao Paulo',
    },
    {
      name: 'Sergipe',
    },
    {
      name: 'Tocantins',
    },
  ],
  42: [
    {
      name: 'Alberta',
    },
    {
      name: 'British Columbia',
    },
    {
      name: 'Manitoba',
    },
    {
      name: 'New Brunswick',
    },
    {
      name: 'Newfoundland and Labrador',
    },
    {
      name: 'Northwest Territories',
    },
    {
      name: 'Nova Scotia',
    },
    {
      name: 'Nunavut',
    },
    {
      name: 'Ontario',
    },
    {
      name: 'Prince Edward Island',
    },
    {
      name: 'Quebec',
    },
    {
      name: 'Saskatchewan',
    },
    {
      name: 'Yukon Territory',
    },
  ],
  47: [
    {
      name: 'Aisen',
    },
    {
      name: 'Antofagasta',
    },
    {
      name: 'Araucania',
    },
    {
      name: 'Atacama',
    },
    {
      name: 'Bio-Bio',
    },
    {
      name: 'Coquimbo',
    },
    {
      name: 'Los Lagos',
    },
    {
      name: 'Magallanes y Antartica Chilena',
    },
    {
      name: 'Maule',
    },
    {
      name: 'O’Higgins',
    },
    {
      name: 'Region Metropolitana',
    },
    {
      name: 'Tarapaca',
    },
    {
      name: 'Valparaiso',
    },
  ],
  104: [
    {
      name: 'Andhra Pradesh',
    },
    {
      name: 'Arunachal Pradesh',
    },
    {
      name: 'Assam',
    },
    {
      name: 'Bihar',
    },
    {
      name: 'Chhattisgarh',
    },
    {
      name: 'Goa',
    },
    {
      name: 'Gujarat',
    },
    {
      name: 'Haryana',
    },
    {
      name: 'Himachal Pradesh',
    },
    {
      name: 'Jharkhand',
    },
    {
      name: 'Karnataka',
    },
    {
      name: 'Kerala',
    },
    {
      name: 'Madhya Pradesh',
    },
    {
      name: 'Maharashtra',
    },
    {
      name: 'Manipur',
    },
    {
      name: 'Meghalaya',
    },
    {
      name: 'Mizoram',
    },
    {
      name: 'Nagaland',
    },
    {
      name: 'Odisha',
    },
    {
      name: 'Punjab',
    },
    {
      name: 'Rajasthan',
    },
    {
      name: 'Sikkim',
    },
    {
      name: 'Tamil Nadu',
    },
    {
      name: 'Telangana',
    },
    {
      name: 'Tripura',
    },
    {
      name: 'Uttar Pradesh',
    },
    {
      name: 'Uttarakhand',
    },
    {
      name: 'West Bengal',
    },
    {
      name: 'Andaman and Nicobar Island',
    },
    {
      name: 'Chandigarh',
    },
    {
      name: 'Dadra and Nagar Haveli and Daman and Diu',
    },
    {
      name: 'Delhi',
    },
    {
      name: 'Ladakh',
    },
    {
      name: 'Lakshadweep',
    },
    {
      name: 'Jammu and Kashmir',
    },
    {
      name: 'Puducherry',
    },
  ],
  114: [
    {
      name: 'Hokkaidō',
    },
    {
      name: 'Tōhoku',
    },
    {
      name: 'Kantō',
    },
    {
      name: 'Chūbu',
    },
    {
      name: 'Kansai',
    },
    {
      name: 'Chūgoku',
    },
    {
      name: 'Shikoku',
    },
    {
      name: 'Kyūshū & Okinawa',
    },
  ],
  144: [
    {
      name: 'Aguascalientes',
    },
    {
      name: 'Baja California',
    },
    {
      name: 'Baja California Sur',
    },
    {
      name: 'Campeche',
    },
    {
      name: 'Chiapas',
    },
    {
      name: 'Chihuahua',
    },
    {
      name: 'Coahuila',
    },
    {
      name: 'Colima',
    },
    {
      name: 'Distrito Federal',
    },
    {
      name: 'Durango',
    },
    {
      name: 'Guanajuato',
    },
    {
      name: 'Guerrero',
    },
    {
      name: 'Hidalgo',
    },
    {
      name: 'Jalisco',
    },
    {
      name: 'Mexico',
    },
    {
      name: 'Michoacan',
    },
    {
      name: 'Morelos',
    },
    {
      name: 'Nayarit',
    },
    {
      name: 'Nuevo Leon',
    },
    {
      name: 'Oaxaca',
    },
    {
      name: 'Puebla',
    },
    {
      name: 'Queretaro',
    },
    {
      name: 'Quintana Roo',
    },
    {
      name: 'San Luis Potosi',
    },
    {
      name: 'Sinaloa',
    },
    {
      name: 'Sonora',
    },
    {
      name: 'Tabasco',
    },
    {
      name: 'Tamaulipas',
    },
    {
      name: 'Tlaxcala',
    },
    {
      name: 'Veracruz',
    },
    {
      name: 'Yucatan',
    },
    {
      name: 'Zacatecas',
    },
  ],
  175: [
    {
      name: 'Amazonas',
    },
    {
      name: 'Ancash',
    },
    {
      name: 'Apurimac',
    },
    {
      name: 'Arequipa',
    },
    {
      name: 'Ayacucho',
    },
    {
      name: 'Cajamarca',
    },
    {
      name: 'Callao',
    },
    {
      name: 'Cusco',
    },
    {
      name: 'Huancavelica',
    },
    {
      name: 'Huanuco',
    },
    {
      name: 'Ica',
    },
    {
      name: 'Junin',
    },
    {
      name: 'La Libertad',
    },
    {
      name: 'Lambayeque',
    },
    {
      name: 'Lima',
    },
    {
      name: 'Loreto',
    },
    {
      name: 'Madre de Dios',
    },
    {
      name: 'Moquegua',
    },
    {
      name: 'Pasco',
    },
    {
      name: 'Piura',
    },
    {
      name: 'Puno',
    },
    {
      name: 'San Martin',
    },
    {
      name: 'Tacna',
    },
    {
      name: 'Tumbes',
    },
    {
      name: 'Ucayali',
    },
  ],
}

export const PaymentProviders = {
  GIGADAT_INTERAC_CPI: 'CPI',
  GIGADAT_INTERAC_ACH: 'ACH',
  GIGADAT_INTERAC_ETO: 'ETO',
  PAY_4_FUN: 'PAY4FUN',
  PAY_4_FUN_GO_PIX: 'PIX',
  PAY_4_FUN_GO_TI: 'BANKTRANSFER',
  INOVAPAY_WALLET: 'INOVAPAY_WALLET',
  INOVAPAY_GATEWAY: 'INOVAPAY_GATEWAY',
  DIRECTA: 'DIRECTA',
  BOLETO: 'BL',
  BANCO_DO_BRASIL: 'BB',
  BRADESCO: 'B',
  CAIXA: 'CA',
  SANTANDER: 'SB',
  ITAU: 'I',
  SICREDI: 'SJ',
  BANCO_ORIGINAL: 'BZ',
  BANRISUL: 'UL',
  LOTERIAS_CAIXA: 'LC',
  SAFRA: 'SF',
  PIX: 'IX',
  INTERAC_ETRANSFER: 'IF',
  INTERAC_ONLINE: 'IR',
  SPEI: 'SE',
  OXXO: 'OX',
  VISA: 'VI',
  VISA_DEBIT: 'VD',
  MASTERCARD: 'MC',
  MASTERCARD_DEBIT: 'MD',
  BANCOMER: 'BV',
  BANORTE: 'BQ',
  TODITO_CASH: 'TC',
  AZTECA: 'AZ',
  WALMART: 'WA',
  ELEVEN: 'EN',
  CIRCULO: 'CU',
  AHORRO: 'FA',
  BODEGA: 'BW',
  BENAVIDES: 'FB',
  SAMS: 'SS',
  SUPERAMA: 'SU',
  WEBPAY: 'WP',
  ESTADO: 'BE',
  BANCO_DE_CHILE: 'BX',
  SANTANDER_CL: 'SC',
  ITAU_CL: 'IA',
  BICE: 'CE',
  BCI: 'CI',
  FALABELLA: 'LL',
  SECURITY: 'TY',
  CREDICHILE: 'LE',
  BCP: 'BC',
  INTERBANK: 'IB',
  BBVA: 'BP',
  CAJA_HUANCAYO: 'HC',
  WESTERN_UNION: 'WU',
  CAJA_CUSCO: 'US',
  PAGOEFECTIVO: 'EF',
  SCOTIABANK: 'ST',
  AREQUIPA: 'JA',
  ICA: 'JI',
  PIURA: 'JP',
  TRUJILLO: 'JR',
  TACNA: 'JT',
  KASNET: 'KE',
  TAMBO: 'TM',
  CAJA_VECINA: 'KV',
  SENCILLITO: 'SL',
  TBANC: 'TB',
  JCB: 'JC',
  AMEX: 'AE',
  BANK_TRANSFER_JP: 'TR',
  PHONE_PE: 'PH',
  NET_BANKING_IN: 'NB',
  UPI: 'UI',
}

export const documentTypes = ['DNI', 'CE']

export const KycStatus = {
  UNVERIFIED: 'Unverified',
  VERIFIED: 'Verified',
}

export const PaymentStatus = {
  SUCCESS: 'success',
  ERROR: 'error',
  RESET: 'reset',
}

export const ModalTypes = {
  RECEIPT: 0,
}

export const PaymentTypes = {
  DEPOSIT: 'deposit',
  WITHDRAW: 'withdrawal',
}

export function accountType(country, intl) {
  const defaultAccountTypes = [
    {
      name: intl.formatMessage({ id: 'payments.accountTypes.checkings' }),
      code: 'C',
    },
    {
      name: intl.formatMessage({ id: 'payments.accountTypes.savings' }),
      code: 'S',
    },
  ]

  switch (country) {
    case Countries.BRAZIL:
      return [
        ...defaultAccountTypes,
        {
          name: intl.formatMessage({
            id: 'payments.accountTypes.jointCheckings',
          }),
          code: 'O',
        },
        {
          name: intl.formatMessage({
            id: 'payments.accountTypes.jointSavings',
          }),
          code: 'P',
        },
      ]

    case Countries.CHILE:
      return [
        ...defaultAccountTypes,
        {
          name: intl.formatMessage({
            id: 'payments.accountTypes.salaryAccount',
          }),
          code: 'V',
        },
      ]

    case Countries.COLOMBIA:
    case Countries.PERU:
    case Countries.URUGUAY:
    case Countries.CHINA:
    case Countries.CANADA:
      return defaultAccountTypes

    default:
      return undefined
  }
}
