import React from 'react'
import styled from 'styled-components'

import media from '../css/media'

import useSizeElement from '../../hooks/useSizeElement'
import useSliding from '../../hooks/useSliding'

import ChevronLeftIcon from '../../images/icon_chevron_left.svg'
import ChevronRightIcon from '../../images/icon_chevron_right.svg'

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`

const SlidesContainer = styled.div`
  display: flex;
  transition: transform 300ms ease 100ms;
  z-index: 3;
  width: 100%;
  overflow-x: scroll;

  ${media.desktop`
    overflow-x: initial;
  `};
`

const Slide = styled.div`
  flex: 0 0 35%;
  transition: transform 300ms ease 100ms;
  margin: 0 0.8rem 0 0;
  position: relative;

  ${media.desktop`
    flex: 0 0 18.4%;
  `};
`

const PrevButton = styled.div`
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  left: 10px;

  ${media.desktop`
    &.on {
      display: block;
    }
  `};
`

const NextButton = styled.div`
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  right: 10px;

  ${media.desktop`
    &.on {
      display: block;
    }
  `};
`

const PaymentSlider = ({ slides }) => {
  const { width, elementRef } = useSizeElement()
  const {
    handlePrev,
    handleNext,
    slideProps,
    containerRef,
    hasNext,
    hasPrev,
  } = useSliding(width, React.Children.count(slides), 5)

  return (
    <>
      <Wrapper ref={elementRef}>
        <SlidesContainer ref={containerRef} {...slideProps}>
          {slides.map((s, i) => (
            <Slide key={i}>{s}</Slide>
          ))}
        </SlidesContainer>
      </Wrapper>

      {hasPrev && (
        <PrevButton className={`${hasPrev ? 'on' : ''}`}>
          <img
            src={ChevronLeftIcon}
            alt='Left'
            width={12}
            onClick={handlePrev}
          />
        </PrevButton>
      )}
      {hasNext && (
        <NextButton className={`${hasNext ? 'on' : ''}`}>
          <img
            src={ChevronRightIcon}
            alt='Right'
            width={12}
            onClick={handleNext}
          />
        </NextButton>
      )}
    </>
  )
}

export default PaymentSlider
