import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import { EnvConfig } from '../../config/EnvConfig'

const GtmScript = () => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (typeof window === 'undefined') return

    const script = document.createElement('script')
    script.src = `https://www.googletagmanager.com/gtag/js?id=${EnvConfig.GATSBY_GA_ANALYTICS_CODE}`
    script.defer = true
    script.addEventListener('load', () => setLoaded(true))
    document.body.appendChild(script)
  }, [])

  useEffect(() => {
    if (typeof window === 'undefined') return

    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }
    gtag('js', new Date())
    gtag('config', `${EnvConfig.GATSBY_GA_ANALYTICS_CODE}`)
  }, [loaded])

  return (
    <>
      <Helmet>
        <script
          defer
          type='text/javascript'
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var
        f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${EnvConfig.GATSBY_GTM_CODE}');`,
          }}
        />
      </Helmet>

      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${EnvConfig.GATSBY_GTM_CODE}" height="0" width="0"
        style="display:none;visibility:hidden"></iframe>
        `,
        }}
      />
    </>
  )
}

export default GtmScript
