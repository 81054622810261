import React, { useEffect, useCallback } from 'react'
import styled from 'styled-components'

import media from '../css/media'
import storageService from '../../services/storageService'

import PaymentSlider from './paymentsSlider'

import { usePaymentsContext } from '../../context/paymentProvider'

const Wrapper = styled.div`
  margin: 0.25rem 0;
  padding: 1rem;
  box-sizing: border-box;
  background-color: #eee;
  height: 100px;
  width: 100%;
  position: relative;

  ${media.desktop`
        margin: .75rem 0 1.75rem;
        border-radius: 4px;
        padding: 1rem 2rem;
    `};
`

const Method = styled.div`
  cursor: pointer;
  height: 68px;
  background-color: #fff;
  border-radius: 3.33px;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;

  &:hover {
    border: 2.22px solid #d7d7d7;
  }

  &.active {
    border: 2.22px solid #31c27c;
  }

  img {
    width: 80%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    margin: 0 auto;
  }
`

const MethodsCarousel = ({ methods }) => {
  const user = storageService.getUser()

  const {
    addPaymentMethods,
    currentMethod,
    setCurrentMethod,
  } = usePaymentsContext()

  const getPaymentDetailsByCountry = useCallback(
    (details) => {
      if (
        details.some(
          (row) =>
            row.country_of_registration === user.country.code &&
            row.player_currency === user.wallet.currency.short_code
        )
      ) {
        return details.filter(
          (row) => row.country_of_registration === user.country.code
        )[0]
      }

      return null
    },
    [user.country.code, user.wallet.currency.short_code]
  )

  const getListOfPaymentMethods = useCallback(
    (methods) => {
      let list = []

      methods.forEach((row) => {
        const paymentDetails = getPaymentDetailsByCountry(row.payment_details)
        if (paymentDetails) list.push(Object.assign(paymentDetails, row))
      })

      return list.sort((a, b) => a.order_number - b.order_number)
    },
    [getPaymentDetailsByCountry]
  )

  useEffect(() => {
    const filterMethodsByUserCountry = (methods) => {
      return methods.filter(
        (row) =>
          row.country_of_registration === user.country.code &&
          row.player_currency === user.wallet.currency.short_code
      )
    }

    const groupedMethods = getListOfPaymentMethods(methods)
    const filteredMethods = filterMethodsByUserCountry(groupedMethods)

    setCurrentMethod(filteredMethods[0])
    addPaymentMethods(filteredMethods)
    // eslint-disable-next-line
  }, [
    getListOfPaymentMethods,
    user.country.code,
    user.wallet.currency.short_code,
  ])

  const renderMethodsSlides = (methods) => {
    const payments = getListOfPaymentMethods(methods)
    return payments.map((row) => {
      return (
        <Method
          key={row.payment_code}
          onClick={() => setCurrentMethod(row)}
          className={
            currentMethod &&
            currentMethod.payment_code.toLowerCase() ===
              row.payment_code.toLowerCase()
              ? 'active'
              : ''
          }
        >
          <picture>
            <img
              align='middle'
              width={row.logo.width}
              height={row.logo.height}
              loading='lazy'
              src={`${row.logo.url}`}
              alt={`${row.logo.alt}`}
            />
          </picture>
        </Method>
      )
    })
  }

  return (
    <Wrapper>
      <PaymentSlider slides={renderMethodsSlides(methods)} />
    </Wrapper>
  )
}

export default MethodsCarousel
