/* eslint-disable */
import React from 'react'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

const InovapayGateway = ({ register, errors, touchedFields }) => {
  const intl = useIntl()

  const banks = [
    { id: 'BANCO_DO_BRASIL', value: 'Banco do Brasil' },
    { id: 'BRADESCO', value: 'Bradesco' },
    { id: 'CAIXA', value: 'Caixa' },
    { id: 'ITAU', value: 'Itau' },
    { id: 'SANTANDER', value: 'Santander' },
  ]

  const accountTypes = [
    {
      name: intl.formatMessage({ id: 'payments.accountTypes.checkings' }),
      code: 'C',
    },
    {
      name: intl.formatMessage({ id: 'payments.accountTypes.savings' }),
      code: 'S',
    },
  ]

  return (
    <>
      <div>
        <label htmlFor='bankAccount'>
          <FormattedMessage id='payments.bankAccount' />
        </label>
        <input
          name='bankAccount'
          className={`${errors.bankAccount ? 'invalid' : ''} ${
            !errors.bankAccount && touchedFields.bankAccount ? 'valid' : ''
          }`}
          {...register('bankAccount', {
            required: true,
          })}
          id='bankAccount'
        />
      </div>
      <div>
        <label htmlFor='accountType'>
          <FormattedMessage id='payments.accountType' />
        </label>
        <select
          name='accountType'
          className={`${errors.accountType ? 'invalid' : ''} ${
            !errors.accountType && touchedFields.accountType ? 'valid' : ''
          }`}
          {...register('accountType', { required: true })}
          id='accountType'
        >
          <option value='' />
          {accountTypes.map((row, i) => (
            <option key={i} value={row.code}>
              {row.name}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor='bankBranch'>
          <FormattedMessage id='payments.bankBranch' />
        </label>
        <input
          name='bankBranch'
          className={`${errors.bankBranch ? 'invalid' : ''} ${
            !errors.bankBranch && touchedFields.bankBranch ? 'valid' : ''
          }`}
          {...register('bankBranch', {
            required: true,
          })}
        />
      </div>
      <div>
        <label htmlFor='bankCpf'>
          <FormattedMessage id='payments.bankCpf' />
        </label>
        <input
          name='bankCpf'
          className={`${errors.bankCpf ? 'invalid' : ''} ${
            !errors.bankCpf && touchedFields.bankCpf ? 'valid' : ''
          }`}
          {...register('bankCpf', {
            required: true,
          })}
        />
      </div>
      <div>
        <label htmlFor='bankCode'>
          <FormattedMessage id='payments.bankCode' />
        </label>
        <select
          name='bankCode'
          className={`${errors.bankCode ? 'invalid' : ''} ${
            !errors.bankCode && touchedFields.bankCode ? 'valid' : ''
          }`}
          {...register('bankCode', { required: true })}
          id='bankCode'
        >
          <option value='' />
          {banks.map((row, i) => (
            <option key={i} value={row.id}>
              {row.value}
            </option>
          ))}
        </select>
      </div>
    </>
  )
}

export default InovapayGateway
