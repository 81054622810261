import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'

import media from '../../css/media'

import { EnvConfig } from '../../../config/EnvConfig'
import {
  PaymentProviders,
  PaymentStatus,
  PaymentTypes,
  ModalTypes,
} from '../../../utils/constants'

import { usePaymentsContext } from '../../../context/paymentProvider'

import storageService from '../../../services/storageService'
import toast from '../../common/toast'

import {
  requestDirectaWithdrawal,
  requestPay4FunWithdrawal,
  requestInovapayGatewayWithdrawal,
  requestInovapayWithdrawal,
  requestGigadatWithdrawal,
} from '../../../adapters/payments'

import Error from '../../common/errorMessage'
import InovapayWallet from './inovapayWallet'
import InovapayGateway from './inovapayGateway'
import InteracAch from './interacAch'
import Directa from './directa'
import { getUserLimits } from '../../../adapters/user'
import { AuthContext } from '../../../context/authProvider'
import FormatAmount from '../../common/formatAmount'
import { useModalContext } from '../../../context/modalProvider'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Header = styled.div.attrs((props) => ({
  margin: props.margin || 'initial',
}))`
  font-weight: 600;
  font-size: 1.4em;
  line-height: 2;
  margin: ${(props) => props.margin};
`

const Content = styled.div`
  width: 100%;
  padding: 1rem;

  ${media.desktop`
        width: 450px;
        margin: 0 auto;
        padding: 0;
    `};
`

const Row = styled.div`
  display: flex;
  flex-direction: column;

  ${media.desktop`
        flex-direction: row;
    `};
`

const Col = styled.div`
  flex: 1 1 100%;
  width: 100%;

  ${media.desktop`
        flex: 1 1 50%;
        width: 50%;
    `};
`

const AmountWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, minmax(0, 33.33%));
  margin: 0 0 1rem 0;
`

const Amount = styled.div`
  background-color: #eee;
  text-align: center;
  color: #000;
  padding: 0.8rem 0;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.9em;

  &.active {
    background-color: #31c27c;
    color: #fff;
  }
`

const InfoText = styled.div`
  padding: 1rem 0 0 0;
`

const Method = ({ withdrawClose }) => {
  const user = storageService.getUser()

  let paymentDetails = null
  const intl = useIntl()
  const { getUserWallet } = useContext(AuthContext)
  const { currentMethod } = usePaymentsContext()
  const { open } = useModalContext()

  const [error, setError] = useState(false)
  const [paymentInProgress, setPaymentInProgress] = useState(false)
  const [withdrawDisabled, setWithdrawDisabled] = useState(false)
  const [withdrawAmount, setWithdrawAmount] = useState('')
  const [userLimits, setUserLimits] = useState([])

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, touchedFields },
  } = useForm()

  useEffect(() => {
    if (currentMethod && paymentDetails) {
      const overLimits =
        withdrawAmount < paymentDetails.minimum_amount ||
        withdrawAmount > paymentDetails.maximum_amount

      setWithdrawDisabled(withdrawAmount <= 0 || overLimits)
    }
  }, [withdrawAmount, paymentDetails, currentMethod])

  useEffect(() => {
    if (!withdrawAmount && paymentDetails) {
      const amount = parseInt(paymentDetails.default_amounts[0].amount)
      setWithdrawAmount(amount)
      setValue('withdrawAmount', amount)
    }

    setError(false)
  }, [paymentDetails, currentMethod.payment_code, setValue, withdrawAmount])

  useEffect(() => {
    const fetchData = async () => {
      const response = await getUserLimits()
      if (response.ok) setUserLimits(response.data.data)
    }
    fetchData()
  }, [])

  useEffect(() => {
    const onPaymentFailed = (e) => {
      try {
        if (typeof e.data === 'string') {
          const data = JSON.parse(e.data)
          if (data && data.action === PaymentStatus.ERROR) {
            toast.error(intl.formatMessage({ id: 'payments.receipt.error' }))
          }

          if (data && data.action === PaymentStatus.SUCCESS) {
            toast.success(
              intl.formatMessage({ id: 'payments.withdraw.success' })
            )

            withdrawClose()
          }
        }
      } catch (ex) {
        console.log('error occured', ex)
      }
    }

    window.addEventListener('message', onPaymentFailed)

    return () => {
      window.removeEventListener('message', onPaymentFailed)
    }
  }, [intl, withdrawClose])

  const onChange = (e) => {
    setWithdrawAmount(e.target.value === '' ? '' : parseInt(e.target.value))
  }

  const onWIthdraw = async (data) => {
    try {
      const code = currentMethod.payment_code.toUpperCase()

      let response = null
      let paymentRequested = false

      setPaymentInProgress(true)

      switch (code) {
        case PaymentProviders.GIGADAT_INTERAC_ACH:
          paymentRequested = true
          response = await requestGigadatWithdrawal({
            amount: withdrawAmount,
            acct: data.acct,
            fi: data.fi,
            transit: data.transit,
            site: `${EnvConfig.SITE_URL}`,
            payment_method: PaymentProviders.GIGADAT_INTERAC_ACH,
          })

          break
        case PaymentProviders.GIGADAT_INTERAC_ETO:
          paymentRequested = true
          response = await requestGigadatWithdrawal({
            amount: withdrawAmount,
            site: `${EnvConfig.SITE_URL}`,
            payment_method: PaymentProviders.GIGADAT_INTERAC_ETO,
          })

          break
        case PaymentProviders.PAY_4_FUN:
          paymentRequested = true
          response = await requestPay4FunWithdrawal({
            amount: withdrawAmount,
          })

          break
        case PaymentProviders.INOVAPAY_GATEWAY:
          paymentRequested = true
          response = await requestInovapayGatewayWithdrawal({
            amount: withdrawAmount,
            account: data.bankAccount,
            branch: data.bankBranch,
            cpf: data.bankCpf,
            payment_method: data.bankCode,
            account_type: data.accountType,
          })

          break
        case PaymentProviders.INOVAPAY_WALLET:
          paymentRequested = true
          response = await requestInovapayWithdrawal({
            amount: withdrawAmount,
            user_login: data.userLogin,
          })

          break
        case PaymentProviders.DIRECTA:
          paymentRequested = true
          response = await requestDirectaWithdrawal({
            bank_code: Number(data.bankCode),
            amount: withdrawAmount,
            user_first_name: data.firstName,
            user_last_name: data.lastName,
            account_type: data.accountType,
            bank_account: data.bankAccount,
            bank_branch: data.bankBranch,
            document_id: data.documentId,
            document_type: data.documentType,
          })

          break
        default:
          break
      }

      if (!paymentRequested) {
        setError('Payment not supported')
      }

      if (response) {
        if (response.ok) {
          const { url: redirectionURL } = response.data.data

          if (redirectionURL) {
            window.location.replace(redirectionURL)
          } else {
            toast.success(
              intl.formatMessage({ id: 'payments.withdrawalReady' })
            )

            withdrawClose()
            getUserWallet()

            response.data.data.status = 'PENDING'

            open({
              title: intl.formatMessage({ id: 'payments.receipt.withdraw' }),
              data: response.data.data,
              type: ModalTypes.RECEIPT,
            })
          }
        }

        if (!response.ok) {
          let message = response.error.message
          switch (response.error.messageCode) {
            case 147:
              {
                // Deposit limit exceeded
                const depositLimit = userLimits.find(
                  (l) => l.type === 'WITHDRAW'
                )
                const amount = intl.formatNumber(depositLimit.amount, {
                  style: 'currency',
                  currency: user.wallet.currency.short_code,
                })

                message = intl
                  .formatMessage({ id: 'payments.withdrawLimitExceeded' })
                  .replace('{}', depositLimit ? `${amount}` : '')
              }
              break
            default:
              break
          }
          setError(message)
        }

        paymentRequested = false
        setPaymentInProgress(false)
      }
    } catch (exc) {
      console.log(exc, exc.message)
      setPaymentInProgress(false)
      setError(exc.message)
    }
  }

  const renderInnerMethodFields = (code, register, errors, touchedFields) => {
    switch (code) {
      case PaymentProviders.INOVAPAY_WALLET:
        return (
          <InovapayWallet
            register={register}
            errors={errors}
            touchedFields={touchedFields}
          />
        )
      case PaymentProviders.INOVAPAY_GATEWAY:
        return (
          <InovapayGateway
            register={register}
            errors={errors}
            touchedFields={touchedFields}
          />
        )
      case PaymentProviders.GIGADAT_INTERAC_ACH:
        return (
          <InteracAch
            register={register}
            errors={errors}
            touchedFields={touchedFields}
          />
        )
      case PaymentProviders.PAY_4_FUN:
      case PaymentProviders.GIGADAT_INTERAC_ETO:
        return <div />
      default:
        return (
          <Directa
            register={register}
            errors={errors}
            touchedFields={touchedFields}
          />
        )
    }
  }

  if (!currentMethod) return null

  if (
    !currentMethod.payment_details.some(
      (row) =>
        row.player_currency === user.wallet.currency.short_code &&
        row.type_of_payment === PaymentTypes.WITHDRAW
    )
  )
    return null

  paymentDetails = currentMethod.payment_details.filter(
    (row) =>
      row.player_currency === user.wallet.currency.short_code &&
      row.type_of_payment === PaymentTypes.WITHDRAW
  )[0]

  return (
    <Wrapper>
      <Content>
        <Header>
          <FormattedMessage id='payments.information' />
        </Header>
        <Row>
          <Col>
            <FormattedMessage id='payments.minimum' />
            :&nbsp;
            <FormatAmount
              amount={paymentDetails.minimum_amount}
              currency={user.wallet.currency.short_code}
              maximumFractionDigits='0'
              useGrouping={
                paymentDetails.minimum_amount.toString().length >= 5
                  ? true
                  : false
              }
            />
          </Col>
          <Col>
            <FormattedMessage id='payments.maximum' />
            :&nbsp;
            <FormatAmount
              amount={paymentDetails.maximum_amount}
              currency={user.wallet.currency.short_code}
              maximumFractionDigits='0'
              useGrouping={
                paymentDetails.maximum_amount.toString().length >= 5
                  ? true
                  : false
              }
            />
          </Col>
        </Row>

        {paymentDetails.customer_information_text.length > 0 && (
          <Row>
            <InfoText>{paymentDetails.customer_information_text}</InfoText>
          </Row>
        )}

        <Header margin='1rem 0 0 0'>
          <FormattedMessage id='payments.amount' />
        </Header>
        <AmountWrapper>
          {paymentDetails.default_amounts.map((row) => (
            <Amount
              key={parseInt(row.amount)}
              onClick={() => setWithdrawAmount(parseInt(row.amount))}
              className={
                parseInt(row.amount) === withdrawAmount ? 'active' : ''
              }
            >
              <FormatAmount
                amount={parseInt(row.amount)}
                currency={user.wallet.currency.short_code}
                maximumFractionDigits='0'
                useGrouping={
                  parseInt(row.amount).toString().length >= 5 ? true : false
                }
              />
            </Amount>
          ))}
        </AmountWrapper>

        <form onSubmit={handleSubmit(onWIthdraw)}>
          <label htmlFor='withdrawAmount'>
            <FormattedMessage id='payments.amount' />
          </label>

          <input
            type='number'
            value={withdrawAmount}
            min={paymentDetails.minimum_amount}
            max={paymentDetails.maximum_amount}
            className={`${errors.withdrawAmount ? 'invalid' : ''} ${
              !errors.withdrawAmount && touchedFields.withdrawAmount
                ? 'valid'
                : ''
            }`}
            {...register('withdrawAmount', {
              required: true,
            })}
            onChange={onChange}
          />

          {renderInnerMethodFields(
            currentMethod.payment_code.toUpperCase(),
            register,
            errors,
            touchedFields
          )}

          <button
            type='submit'
            disabled={withdrawDisabled || paymentInProgress}
            className={`${
              withdrawDisabled || paymentInProgress ? 'disabled' : ''
            }`}
          >
            <FormattedMessage id='common.withdraw' />
            &nbsp;
            <FormatAmount
              amount={withdrawAmount}
              currency={user.wallet.currency.short_code}
              maximumFractionDigits='0'
              useGrouping={withdrawAmount.toString().length >= 5 ? true : false}
            />
          </button>
        </form>

        {currentMethod.payment_code.toUpperCase() ===
          PaymentProviders.GIGADAT_INTERAC_ETO && (
          <small>® Trade-mark of Interac Corp. Used under license.</small>
        )}

        {error && <Error text={`${error}`} />}
      </Content>
    </Wrapper>
  )
}

export default Method
