import React, {
  useState,
  useEffect,
  useReducer,
  useMemo,
  useContext,
  createContext,
} from 'react'

import { AuthContext } from './authProvider'
import { getRollbackWithdrawalSettings } from '../adapters/settings'

const PaymentsContext = createContext()

const ActionTypes = {
  SET_METHODS: 'SET_METHODS',
  SET_CURRENT_METHOD: 'SET_CURRENT_METHOD',
  SET_COUNTRIES: 'SET_COUNTRIES',
}

const paymentsReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.SET_METHODS: {
      return {
        ...state,
        paymentMethods: action.payload,
      }
    }
    case ActionTypes.SET_CURRENT_METHOD: {
      return {
        ...state,
        currentMethod: action.payload,
      }
    }

    default:
      throw new Error(`Action is not supported: ${action.type}`)
  }
}

const initialState = {
  paymentMethods: [],
  currentMethod: null,
}

export const PaymentsProvider = (props) => {
  const { isLoggedIn } = useContext(AuthContext)
  const [state, dispatch] = useReducer(paymentsReducer, initialState)
  const [
    isRollbackWithdrawalAllowed,
    setIsRollbackWithdrawalAllowed,
  ] = useState(false)

  useEffect(() => {
    const getRollback = async () => {
      const value = await getRollbackWithdrawalSettings()
      setIsRollbackWithdrawalAllowed(value)
    }
    if (isLoggedIn) {
      getRollback()
    }
  }, [isLoggedIn])

  const value = useMemo(
    () => ({ state, dispatch, isRollbackWithdrawalAllowed }),
    [state, isRollbackWithdrawalAllowed]
  )

  return <PaymentsContext.Provider value={value} {...props} />
}

export const usePaymentsContext = () => {
  const context = useContext(PaymentsContext)

  if (!context) {
    throw new Error('usePaymentsContext must be used inside a PaymentsProvider')
  }

  const { state, dispatch, isRollbackWithdrawalAllowed } = context
  const { paymentMethods, currentMethod } = state

  const addPaymentMethods = (methods) => {
    dispatch({ type: ActionTypes.SET_METHODS, payload: methods })
  }

  const setCurrentMethod = (method) => {
    dispatch({ type: ActionTypes.SET_CURRENT_METHOD, payload: method })
  }

  return {
    paymentMethods,
    currentMethod,
    addPaymentMethods,
    setCurrentMethod,
    isRollbackWithdrawalAllowed,
  }
}
