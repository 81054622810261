import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import styled, { keyframes } from 'styled-components'

import media from '../css/media'

import TimesIcon from '../../images/icon_times_black.svg'

const Times = styled.img`
  width: 15px;
`

const fadeInAnimation = keyframes`
    0% { animation-timing-function: cubic-bezier(0.2242, 0.7499, 0.3142, 0.8148); opacity: 0; }
    100% { opacity: 1; }
`

const ModalWrapper = styled.div.attrs((props) => ({
  align: props.theme === 'search' ? 'start' : 'center',
}))`
  position: fixed;
  overflow: hidden;
  top: ${(props) => (props.theme === 'search' ? '50px' : '0')};
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.align};
  z-index: 999999;
  box-sizing: border-box;

  &.modal-fade {
    animation: ${fadeInAnimation} 1s 1 linear;
    animation-fill-mode: forwards;
    opacity: 0;
  }

  ${media.tablet`
    top: ${(props) => (props.theme === 'search' ? '60px' : '0')};
  `};
`

const ModalOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const ModalClose = styled.div`
  position: absolute;
  right: 20px;
  top: 0px;
  color: #fff;
  cursor: pointer;
  font-size: 1em;

  ${media.tablet`
    font-size: 1.25em;
    color: #000;
    top: 20px;
  `};
`

const ModalBody = styled.div.attrs((props) => ({
  borderRadius: props.theme === 'search' ? '0 0 8px 8px' : '8px',
}))`
  z-index: 2;
  position: relative;
  margin: 0 auto;
  background-color: #fff;
  background-clip: padding-box;
  height: 100vh;
  min-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;

  ${(props) => {
    switch (props.theme) {
      default:
        return `padding: 2.8rem 0;`
      case 'account':
        return `padding: 0;`
    }
  }};

  ${media.tablet`
    height: initial;
    max-height: 90vh;
    max-width: 700px;
    min-height: ${({ minHeight }) =>
      minHeight ? 'calc(100% - 30rem)' : 'auto'};
    border-radius: ${(props) => props.borderRadius};
    padding: 1rem 2rem 2rem;

    ${(props) => {
      switch (props.theme) {
        default:
          return `min-width: auto;`
        case 'search':
          return `min-width: 600px;`
        case 'payments':
          return `min-width: 800px;`
      }
    }}
  `};
`

const ModalTitle = styled.div`
  text-align: center;
  font-size: 0.9em;
  padding: 0;

  ${media.tablet`
    font-size: 1.6em;
    font-weight: 600;
    margin: 0 0 0.5rem 0;
  `};
`

const ModalHeader = styled.div`
  text-align: center;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  line-height: 50px;
  color: #fff;
  margin: 0 0 1rem 0;

  ${media.tablet`
    position: initial;
    background-color: #fff;
    margin: 0;
    color: #000;
    line-height: initial;
  `};
`

const Modal = (props) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      document.body.classList.add('overflow-hidden')
    }

    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [])

  if (!props.isOpen) return null

  const validateMinHeight = (minHeight) => {
    if (minHeight === null || minHeight === 'undefined') {
      return true
    }

    return minHeight
  }

  return createPortal(
    props.isOpen ? (
      <ModalWrapper {...props} className={`${props.fade ? 'modal-fade' : ''}`}>
        <ModalOverlay />
        <ModalBody
          minHeight={validateMinHeight(props?.bodyMinHeight)}
          {...props}
        >
          {props.theme !== 'search' && props.theme !== 'account' && (
            <ModalHeader>
              <ModalClose role='button' onClick={props.onClose}>
                <Times src={TimesIcon} alt='Close icon' />
              </ModalClose>
              <ModalTitle>{props.title}</ModalTitle>
            </ModalHeader>
          )}
          {props.children}
        </ModalBody>
      </ModalWrapper>
    ) : null,
    typeof document !== `undefined` ? document.body : null
  )
}

Modal.defaultProps = {
  theme: `default`,
  title: ``,
  fade: false,
  isOpen: false,
  onClose: null,
}

Modal.propTypes = {
  theme: PropTypes.string,
  title: PropTypes.string,
  fade: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Modal
