import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

import Image from './image'
import NavLink from './navLink'
import UserBalance from './userBalance'
import { AuthContext } from '../../context/authProvider'
import useModal from '../../hooks/useModal'
import Modal from '../common/modal'
import Search from '../common/search'

import SearchIcon from '../../images/icon_search_black.svg'
import TimesIcon from '../../images/icon_times_white.svg'

const Left = styled.div`
  flex: 0 0 60%;
  width: 60%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`

const Right = styled.div`
  flex: 0 0 40%;
  width: 40%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;

  > img {
    width: 30px;
    height: 30px;
  }

  .cta {
    &:last-child {
      margin: 0 0 0 0.5rem;
    }
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  font-weight: 600;
`

const SearchInput = styled.input.attrs({ type: 'text' })`
  width: 600px;
  height: 36px;
  border-radius: 20px;
  border: 0;
  outline: none;
  padding: 0 0.75rem 0 2.25rem;
  letter-spacing: -0.14px;
  background: #fff url(${SearchIcon}) 5px no-repeat;
  background-size: 30px;
  margin: -4px auto;
`

const DesktopNav = (props) => {
  const { data, locale } = props

  const intl = useIntl()
  const { isLoggedIn } = useContext(AuthContext)
  const { openModal, closeModal, isOpen } = useModal()
  const [searchTerm, setSearchTerm] = useState('')

  const handleChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const leftSection = data
    .filter((m) => m.section === 'left')
    .sort((a, b) => parseInt(a.position) - parseInt(b.position))

  const rightSection = data
    .filter((m) => m.section === 'right')
    .sort((a, b) => parseInt(a.position) - parseInt(b.position))

  const cashbackCta = data.some((m) => m.className === 'cta cashback')
    ? data.filter((m) => m.className === 'cta cashback')[0]
    : null
  const mainSearch = data.some((m) => m.className === 'main-search')
    ? data.filter((m) => m.className === 'main-search')[0]
    : null

  const setNavUrl = (menu) => {
    if (isLoggedIn && menu.link_logged_in.url !== '') {
      return `/${intl.locale}${menu.link_logged_in.url}`
    }
    return `/${intl.locale}${menu.link.url}`
  }

  return (
    <>
      {!isOpen && (
        <>
          <Left>
            {leftSection.map((menuItem, i) => {
              return menuItem.type.toLowerCase() === 'image' ? (
                <Link
                  key={i}
                  title={menuItem.link.title}
                  to={setNavUrl(menuItem)}
                >
                  <Image menuItem={menuItem} />
                </Link>
              ) : (
                <NavLink key={i} locale={locale} menuItem={menuItem} />
              )
            })}
          </Left>
          <Right>
            {!isLoggedIn &&
              rightSection.map((menuItem, i) => {
                return menuItem.type.toLowerCase() === 'image' ? (
                  <Image onClick={openModal} key={i} menuItem={menuItem} />
                ) : (
                  <NavLink key={i} locale={locale} menuItem={menuItem} />
                )
              })}
            {isLoggedIn && (
              <>
                <NavLink locale={locale} menuItem={cashbackCta} />
                <Image onClick={openModal} menuItem={mainSearch} />
                <UserBalance cashbackCta={cashbackCta} />
              </>
            )}
          </Right>
        </>
      )}

      {isOpen && (
        <SearchInput
          placeholder={intl.formatMessage({
            id: 'navigation.searchPlaceholder',
          })}
          value={searchTerm}
          onChange={handleChange}
        />
      )}

      {isOpen && (
        <CloseButton onClick={closeModal}>
          <img src={TimesIcon} alt='' width={20} height={20} />
        </CloseButton>
      )}

      {isOpen && (
        <Modal theme='search' isOpen={isOpen} onClose={closeModal}>
          <Search locale={locale} query={searchTerm} />
        </Modal>
      )}
    </>
  )
}

export default DesktopNav
