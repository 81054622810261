/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'

import { getDirectaBanks } from '../../../adapters/payments'
import storageService from '../../../services/storageService'
import { Countries, documentTypes, accountType } from '../../../utils/constants'

const Directa = ({ register, errors, touchedFields }) => {
  const user = storageService.getUser()
  const intl = useIntl()
  const [banks, setBanks] = useState([])

  const notWithBankCode = [
    Countries.ARGENTINA,
    Countries.INDIA,
    Countries.PERU,
  ].includes(user.country.id)

  const withAccountType = [
    Countries.BRAZIL,
    Countries.CHILE,
    Countries.COLOMBIA,
    Countries.PERU,
    Countries.URUGUAY,
    Countries.CHINA,
  ].includes(user.country.id)

  const withBankBranch = [
    Countries.BRAZIL,
    Countries.INDIA,
    Countries.URUGUAY,
  ].includes(user.country.id)

  const notWithDocumentId = [Countries.CHINA].includes(user.country.id)
  const withDocumentType = [Countries.COLOMBIA, Countries.PERU].includes(
    user.country.id
  )

  useEffect(() => {
    const fetchData = async () => {
      const response = await getDirectaBanks()
      if (response.ok) setBanks(response.data.data)
    }
    fetchData()
  }, [])

  return (
    <>
      <div>
        <label htmlFor='firstName'>
          <FormattedMessage id='userProfile.name' />
        </label>
        <input
          name='firstName'
          className={`${errors.firstName ? 'invalid' : ''} ${
            !errors.firstName && touchedFields.firstName ? 'valid' : ''
          }`}
          {...register('firstName', {
            required: true,
          })}
        />
      </div>
      <div>
        <label htmlFor='lastName'>
          <FormattedMessage id='userProfile.lastName' />
        </label>
        <input
          name='lastName'
          className={`${errors.lastName ? 'invalid' : ''} ${
            !errors.lastName && touchedFields.lastName ? 'valid' : ''
          }`}
          {...register('lastName', {
            required: true,
          })}
        />
      </div>
      {!notWithBankCode && (
        <div>
          <label htmlFor='bankCode'>
            <FormattedMessage id='payments.bankCode' />
          </label>
          <select
            className={`${errors.bankCode ? 'invalid' : ''} ${
              !errors.bankCode && touchedFields.bankCode ? 'valid' : ''
            }`}
            {...register('bankCode', { required: true })}
            id='bankCode'
            name='bankCode'
          >
            <option value='' />
            {banks.map((row, i) => (
              <option key={i} value={row.code}>
                {row.name}
              </option>
            ))}
          </select>
        </div>
      )}
      {withAccountType && (
        <div>
          <label htmlFor='accountType'>
            <FormattedMessage id='payments.accountType' />
          </label>
          <select
            className={`${errors.accountType ? 'invalid' : ''} ${
              !errors.accountType && touchedFields.accountType ? 'valid' : ''
            }`}
            {...register('accountType', { required: true })}
            id='accountType'
            name='accountType'
          >
            <option value='' />
            {accountType(user.country.id, intl).map((row, i) => (
              <option key={i} value={row.code}>
                {row.name}
              </option>
            ))}
          </select>
        </div>
      )}
      {withBankBranch && (
        <div>
          <label htmlFor='bankBranch'>
            <FormattedMessage id='payments.bankBranch' />
          </label>
          <input
            name='bankBranch'
            className={`${errors.bankBranch ? 'invalid' : ''} ${
              !errors.bankBranch && touchedFields.bankBranch ? 'valid' : ''
            }`}
            {...register('bankBranch', {
              required: true,
            })}
          />
        </div>
      )}
      <div>
        <label htmlFor='bankAccount'>
          <FormattedMessage id='payments.bankAccount' />
        </label>
        <input
          name='bankAccount'
          className={`${errors.bankAccount ? 'invalid' : ''} ${
            !errors.bankAccount && touchedFields.bankAccount ? 'valid' : ''
          }`}
          {...register('bankAccount', {
            required: true,
          })}
        />
      </div>
      {!notWithDocumentId && (
        <div>
          <label htmlFor='documentId'>
            <FormattedMessage id='payments.documentId' />
          </label>
          <input
            name='documentId'
            className={`${errors.documentId ? 'invalid' : ''} ${
              !errors.documentId && touchedFields.documentId ? 'valid' : ''
            }`}
            {...register('documentId', {
              required: true,
            })}
          />
        </div>
      )}
      {withDocumentType && (
        <div>
          <label htmlFor='documentType'>
            <FormattedMessage id='payments.documentType' />
          </label>
          <select
            name='documentType'
            className={`${errors.documentType ? 'invalid' : ''} ${
              !errors.documentType && touchedFields.documentType ? 'valid' : ''
            }`}
            {...register('documentType', {
              required: true,
            })}
          >
            <option value='' />
            {documentTypes.map((documentType, i) => (
              <option key={i} value={documentType}>
                {documentType}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  )
}

export default Directa
