import React, { useState, useContext } from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import * as dayjs from 'dayjs'

import utc from 'dayjs/plugin/utc'

import Grid from '../common/grid'
import Btn from '../common/button'
import { cancelWithdrawals } from '../../adapters/payments'
import { AuthContext } from '../../context/authProvider'
import toast from '../common/toast'
import FormatAmount from '../common/formatAmount'

dayjs.extend(utc)

const Wrapper = styled.div`
  margin: 2rem 0 0 0;
`

const Message = styled.p`
  text-align: center;
`

const ProceedToDeposit = styled(Message)`
  font-size: 0.9em;
  text-decoration: underline;
  margin: 0;
  cursor: pointer;
`

const PendingWithdrawal = ({
  user,
  data,
  totals,
  depositClose,
  proceedToDeposit,
}) => {
  const intl = useIntl()
  const { getUserWallet } = useContext(AuthContext)

  const [step, setStep] = useState(0)

  const columns = [
    {
      columnName: 'time',
      displayName: intl.formatMessage({ id: 'account.transactions.date' }),
      size: 3,
    },
    {
      columnName: 'name',
      displayName: intl.formatMessage({ id: 'account.transactions.method' }),
      size: 3,
    },
    {
      columnName: 'amount',
      displayName: intl.formatMessage({ id: 'account.transactions.amount' }),
      size: 3,
    },
    {
      columnName: 'status',
      displayName: intl.formatMessage({ id: 'account.transactions.status' }),
      size: 3,
    },
  ]

  const onCancelWithdrawal = () => {
    setStep(1)

    if (step === 1) {
      cancelWithdrawals(data.map((row) => parseInt(row.id)))
        .then((_) => {
          getUserWallet()
          depositClose()
          toast.success(
            intl.formatMessage({
              id: 'account.transactions.transactionCancelled',
            })
          )
        })
        .catch((_) =>
          toast.error(intl.formatMessage({ id: 'common.unexpectedError' }))
        )
    }
  }

  const formatTransactionsData = (data) => {
    if (!data) {
      return []
    }

    let formattedData = []

    data.forEach((row) => {
      let gridRow = {}

      gridRow['transactionID'] = { value: row.id }
      gridRow['time'] = {
        value: dayjs.utc(row.created_at).format('DD.MM.YYYY HH:mm:ss'),
      }
      gridRow['name'] = {
        value: row.payment_method ? row.payment_method.name : '-',
      }

      gridRow['amount'] = {
        value: (
          <FormatAmount
            amount={row.amount}
            currency={user.wallet.currency.short_code}
          />
        ),
      }

      let color = ''
      switch (row.status.toLowerCase()) {
        case 'canceled_by_user':
          color = '#343434'
          break
        case 'created':
        case 'pending':
          color = '#FFB300'
          break
        case 'declined':
        case 'not_ok':
        case 'declined_on_platform':
          color = '#FF3C3C'
          break
        case 'ok':
        case 'accepted_on_platform':
        default:
          color = '#31C27C'
          break
      }

      gridRow['status'] = {
        value: intl.formatMessage({
          id: `account.transactions.types.${row.status}`,
        }),
        color,
      }

      formattedData.push(gridRow)
    })

    return formattedData.sort((a, b) => {
      if (b.transactionID.value < a.transactionID.value) {
        return -1
      }
      if (b.transactionID.value > a.transactionID.value) {
        return 1
      }
      return 0
    })
  }

  return (
    <Wrapper>
      {step === 0 && (
        <>
          <Message
            dangerouslySetInnerHTML={{
              __html: intl
                .formatMessage({ id: 'cancelWithdraw.totalPendingAmount' })
                .replace(
                  '{0}',
                  intl.formatNumber(totals?.total_withdrawal_amount, {
                    style: 'currency',
                    currency: user.wallet.currency.short_code,
                  })
                ),
            }}
          />
          <Message
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'cancelWithdraw.doYouWantToCancelWithdraw',
              }),
            }}
          />

          <Grid columns={columns} data={formatTransactionsData(data)} />

          <Message>
            <Btn
              text={intl.formatMessage({ id: 'cancelWithdraw.cancelWithdraw' })}
              bgcolor='#31c27c'
              padding='.32rem 1rem'
              bordercolor='#31c27c'
              fontSize='.85em'
              margin='0'
              onClick={onCancelWithdrawal}
            />
          </Message>

          <ProceedToDeposit
            onClick={proceedToDeposit}
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'cancelWithdraw.noProceedToDeposit',
              }),
            }}
          />
        </>
      )}

      {step === 1 && (
        <>
          <Message
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'cancelWithdraw.confirmToCancelWithdrawals',
              }),
            }}
          />

          <Message>
            <Btn
              text={intl.formatMessage({ id: 'cancelWithdraw.confirm' })}
              bgcolor='#31c27c'
              padding='.32rem 1rem'
              bordercolor='#31c27c'
              fontSize='.85em'
              margin='0'
              onClick={onCancelWithdrawal}
            />
          </Message>

          <ProceedToDeposit
            onClick={proceedToDeposit}
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'cancelWithdraw.noProceedToDeposit',
              }),
            }}
          />
        </>
      )}
    </Wrapper>
  )
}

export default PendingWithdrawal
