import React, { useContext } from 'react'
import styled from 'styled-components'

import media from '../css/media'
import { AuthContext } from '../../context/authProvider'
import FormatAmount from '../common/formatAmount'

const AmountWrapper = styled.div`
  color: #fad749;
  font-size: 0.8em;
  cursor: pointer;

  @media (max-width: 320px) {
    display: none;
  }

  ${media.desktop`
    font-size: 1em;
  `};
`

const Amount = (props) => {
  const { user } = useContext(AuthContext)

  return (
    <AmountWrapper onClick={props.onClick}>
      <FormatAmount
        amount={user?.wallet?.amount}
        currency={user?.wallet?.currency?.short_code}
      />
    </AmountWrapper>
  )
}

export default Amount
