import React from 'react'
import styled from 'styled-components'

const Btn = styled.button.attrs((props) => ({
  type: props.type || 'button',
  width: props.width || 'auto',
  fontSize: props.fontSize || '1em',
  lineheight: props.lineheight || '1.5',
  bgcolor: props.bgcolor || 'transparent',
  margin: props.margin || '1rem 0',
  bordercolor: props.bordercolor || '#fff',
  color: props.color || '#fff',
  padding: props.padding || '.2rem .75rem .2rem',
}))`
  text-transform: uppercase;
  letter-spacing: 2px;
  color: ${(props) => props.color};
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.lineheight};
  padding: ${(props) => props.padding};
  border-radius: 6px;
  background-color: ${(props) => props.bgcolor};
  user-select: none;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  border: 2px solid ${(props) => props.bordercolor};

  &.disabled {
    opacity: 0.6;
    cursor: initial;
  }
`

const Button = (props) => <Btn {...props}>{props.text}</Btn>

export default Button
