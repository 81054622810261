import React, { useContext } from 'react'
import styled from 'styled-components'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'
import { Link, useStaticQuery, graphql } from 'gatsby'

import media from '../../components/css/media'

import icon_playbutton from '../../images/icon_playbutton.svg'
import { AuthContext } from '../../context/authProvider'

const Row = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin: 0 0 1rem 0;
`

const Thumbnail = styled.div`
  height: 80px;
  width: 120px;
  border-radius: 4px;
  overflow: hidden;

  img {
    object-fit: cover;
    object-position: center;
  }
`

const GameTitle = styled.div`
  font-weight: 600;
`

const GameDetails = styled.div`
  flex: 2 1 20%;
`

const GameLinks = styled.div`
  flex: 1 0 14%;
  text-align: center;

  &.playNow {
    flex: 1 0 14%;
  }

  ${media.tablet`
    &.playNow {
      flex: 1 0 28%;
    }
  `};
`

const PlayForFun = styled(Link)`
  color: #000;
  display: none;

  ${media.tablet`
    display: block;
  `};
`

const Button = styled(Link)`
  padding: 0.7rem 0;
  width: 40px;
  border-radius: 50%;
  background: #fad749 url(${icon_playbutton}) 50% no-repeat;
  background-size: 24px;
  text-decoration: none;
  color: transparent;

  &.playNow {
    width: 55px;
    display: block;
  }

  ${media.tablet`
    background-position: 6px center;
    padding: .55rem 1rem .55rem 2.5rem;
    color: #000;
    border-radius: 4px;
    text-align: left;
    color: #000;

    &.playNow {
      width: auto;
    }
  `};
`

const GameRow = ({ game }) => {
  const { isLoggedIn } = useContext(AuthContext)
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    {
      customSlugsApi {
        values {
          lang
          slugs {
            generic_route
            language_route
          }
        }
      }
    }
  `)

  const {
    customSlugsApi: { values },
  } = data

  const dataExistForLocale = values.some((row) => row.lang === intl.locale)
  const dataExistForPage =
    dataExistForLocale &&
    values.filter((row) => row.lang === intl.locale)[0].slugs
  const slugs = dataExistForPage
    ? values.filter((row) => row.lang === intl.locale)[0].slugs
    : []

  const casinoSlug = slugs.some(
    (slug) => slug.generic_route.toLowerCase() === 'casino'
  )
    ? slugs.filter((slug) => slug.generic_route.toLowerCase() === 'casino')[0]
        .language_route
    : 'casino'

  const loginSlug = slugs.some(
    (slug) => slug.generic_route.toLowerCase() === 'login'
  )
    ? slugs.filter((slug) => slug.generic_route.toLowerCase() === 'login')[0]
        .language_route
    : 'login'

  return (
    <Row>
      <Thumbnail>
        <picture>
          <img
            src={`${game.thumbnail.sizes.medium}`}
            alt={`${game.description}`}
            width={120}
            height={80}
            loading='lazy'
          />
        </picture>
      </Thumbnail>
      <GameDetails>
        <GameTitle>{game.title}</GameTitle>
        <div>{game.providerName}</div>
      </GameDetails>
      {!isLoggedIn && (
        <>
          <GameLinks>
            <PlayForFun
              to={`/${intl.locale}/${casinoSlug}/game/${game.slug}/`}
              title={`${game.title}`}
            >
              <FormattedMessage id='casino.playForFun' />
            </PlayForFun>
          </GameLinks>
          <GameLinks>
            <Button
              to={`/${intl.locale}/${loginSlug}/`}
              title='Login to KTO.com'
            >
              <FormattedMessage id='common.login' />
            </Button>
          </GameLinks>
        </>
      )}
      {isLoggedIn && (
        <GameLinks className='playNow'>
          <Button
            to={`/${intl.locale}/${casinoSlug}/game/${game.slug}/`}
            title='Play Now'
            className='playNow'
          >
            <FormattedMessage id='casino.playNow' />
          </Button>
        </GameLinks>
      )}
    </Row>
  )
}

export default GameRow
