import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'gatsby-plugin-intl'

const GridWrapper = styled.div`
  margin: 0 0 2rem 0;
`

const Header = styled.div`
  background-color: #eee;
  border-radius: 4px;
  font-weight: 600;

  .cell {
    word-break: break-word;
  }
`

const Cell = styled.div.attrs((props) => ({
  color: props.color || '',
  size: props.size || '',
}))`
  word-break: break-word;
  color: ${(props) => props.color};
  flex: ${(props) => props.size};
`

const Row = styled.div`
  display: flex;
  padding: 1.25rem 1rem;
  border-bottom: 2px solid #eee;
`

const NoData = styled.div`
  text-align: center;
  line-height: 60px;
  border-bottom: 1px solid #eee;
`

const Grid = (props) => (
  <GridWrapper>
    <Header>
      <Row>
        {props.columns.map((column, index) => {
          return (
            <Cell key={index} size={column.size}>
              {column.displayName}
            </Cell>
          )
        })}
      </Row>
    </Header>
    {props.data.length > 0 && (
      <>
        {props.data.map((row, index) => {
          return (
            <Row key={`row-${index}`}>
              {props.columns.map((column, columnIndex) => {
                let cell = row[column.columnName]
                if (cell && typeof cell.value === 'string') {
                  return (
                    <Cell
                      key={columnIndex}
                      size={column.size}
                      color={cell?.color}
                      dangerouslySetInnerHTML={{
                        __html: cell.value,
                      }}
                    />
                  )
                } else {
                  return (
                    <Cell
                      key={columnIndex}
                      size={column.size}
                      color={cell?.color}
                    >
                      {cell?.value}
                    </Cell>
                  )
                }
              })}
            </Row>
          )
        })}
      </>
    )}
    {props.data.length === 0 && (
      <NoData>
        <FormattedMessage id='grid.noDataAvailable' />
      </NoData>
    )}
  </GridWrapper>
)

export default Grid
