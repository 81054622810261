import { coreApi } from './base'

export const getUserLimits = async () => {
  try {
    const response = await coreApi.get('/limit')
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getUserScheduledLimits = async (id) => {
  try {
    const response = await coreApi.get(`/limit/${id}/scheduled-actions`)
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const resendActivationEmail = async (email, url) => {
  try {
    const response = await coreApi.get(
      `/auth/activate/${encodeURIComponent(email)}?activation_url=${url}`
    )
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getSportsbookToken = async () => {
  try {
    const response = await coreApi.get(`/sportsbook/v2/altenar/token`)
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getCasinoToken = async () => {
  try {
    const response = await coreApi.get(`/casino/oryx/token`)
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getUserWallet = async () => {
  try {
    const response = await coreApi.get(`/wallet`)
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getUserLimitPeriods = async () => {
  try {
    const response = await coreApi.get(`/limit/period`)
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const setUserLimit = async (model) => {
  try {
    const response = await coreApi.post(`/limit`, model)
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const setUserUpdateLimit = async (model) => {
  try {
    const response = await coreApi.put(`/limit/${model.id}`, model)
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const updateAccount = async (model) => {
  try {
    const response = await coreApi.put(`/user`, model)
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const requestEmailChange = async (email) => {
  try {
    const response = await coreApi.put(`/auth/email`, { email })
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const updatePassword = async (model) => {
  try {
    const response = await coreApi.put(`/auth/password`, model)
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const cashoutVault = async (model) => {
  try {
    const response = await coreApi.post(`/vault/transfer`, model)
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getUserNotification = async () => {
  try {
    const response = await coreApi.get(`/user/user-notification`)
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    console.log(error)
    return { ok: false, error: error.response.data }
  }
}

export const setVisualizeNotification = async () => {
  try {
    const response = await coreApi.post(`/user/visualize-notification`)
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}
