import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useIntl } from 'gatsby-plugin-intl'
import { navigate, useStaticQuery, graphql } from 'gatsby'

import { sizes } from '../css/media'
import storageService from '../../services/storageService'
import Btn from '../../components/common/button'
import Amount from './amount'

import icon_hidebalance from '../../images/icon_hidebalance.svg'
import icon_showbalance from '../../images/icon_showbalance.svg'
import arrow_down_icon from '../../images/arrow_down_icon.svg'
import icon_nav_deposit_mobile from '../../images/icon_nav_deposit_mobile.svg'

import useModal from '../../hooks/useModal'
import Deposit from '../payments/deposit/deposit'
import Modal from '../common/modal'
import Withdraw from '../payments/withdraw/withdraw'
import DropDownMenu from './dropdownMenu'

const Wrapper = styled.div`
  line-height: 35px;
  display: flex;
`

const Name = styled.div`
  cursor: pointer;
`

const BalanceToggle = styled.button`
  width: 28px;
  height: 35px;
  margin: 0 0.5rem;
  outline: none;
  background: url(${icon_hidebalance}) 50% no-repeat;

  &.active {
    background: url(${icon_showbalance}) 50% no-repeat;
  }
`

const MenuToggle = styled.button`
  width: 16px;
  height: 35px;
  outline: none;
  background: url(${arrow_down_icon}) 50% no-repeat;
  background-size: contain;
`

const DepositButton = styled.button`
  width: 30px;
  height: 30px;
  outline: none;
  background: url(${icon_nav_deposit_mobile}) center no-repeat;
  background-size: contain;
  margin: 0 0 0 0.5rem;
`

const UserBalance = ({ cashbackCta }) => {
  const intl = useIntl()
  const breakpoint = sizes.desktop
  const user = storageService.getUser()

  const { openModal, closeModal, isOpen } = useModal()
  const {
    openModal: openWithdraw,
    closeModal: closeWithdraw,
    isOpen: isWithdrawOpen,
  } = useModal()

  const data = useStaticQuery(graphql`
    {
      customSlugsApi {
        values {
          lang
          slugs {
            generic_route
            language_route
          }
        }
      }
    }
  `)

  const {
    customSlugsApi: { values },
  } = data

  const dataExistForLocale = values.some((row) => row.lang === intl.locale)
  const dataExistForPage =
    dataExistForLocale &&
    values.filter((row) => row.lang === intl.locale)[0].slugs
  const slugs = dataExistForPage
    ? values.filter((row) => row.lang === intl.locale)[0].slugs
    : []

  const profileSlug = slugs.some(
    (slug) => slug.generic_route.toLowerCase() === 'account/profile'
  )
    ? slugs.filter(
        (slug) => slug.generic_route.toLowerCase() === 'account/profile'
      )[0].language_route
    : 'account/profile'

  const [toggleBalance, setToggleBalance] = useState(
    storageService.getValue('show_balance')
  )
  const [toggleSubMenu, setToggleSubMenu] = useState(false)
  const [width, setWidth] = React.useState(
    typeof window !== 'undefined' ? window.innerWidth : breakpoint
  )

  const onResizeWindow = () => {
    setWidth(window.innerWidth)
  }

  const handleToggleBalance = () => {
    if (toggleBalance) {
      storageService.removeValue('show_balance')
    } else {
      storageService.setValue('show_balance', 'true')
    }
    setToggleBalance(!toggleBalance)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResizeWindow)
    }

    return () => {
      window.removeEventListener('resize', onResizeWindow)
    }
  })

  if (!user) return null

  if (width > breakpoint) {
    return (
      <Wrapper>
        <Name onClick={() => setToggleSubMenu(!toggleSubMenu)}>
          {user.first_name}
        </Name>
        &nbsp;
        {!toggleBalance && (
          <Amount onClick={() => setToggleSubMenu(!toggleSubMenu)} />
        )}
        <BalanceToggle
          type='button'
          className={`${toggleBalance ? 'active' : ''}`}
          onClick={handleToggleBalance}
        />
        <MenuToggle
          type='button'
          onClick={() => setToggleSubMenu(!toggleSubMenu)}
        />
        <Btn
          text={intl.formatMessage({ id: 'navigation.deposit' })}
          bgcolor='#31c27c'
          padding='.32rem 1rem'
          margin='0 0 0 2rem'
          bordercolor='#31c27c'
          fontSize='.85em'
          onClick={openModal}
        />
        {toggleSubMenu && (
          <DropDownMenu
            cashbackCta={cashbackCta}
            openDeposit={openModal}
            openWithdraw={openWithdraw}
            profileSlug={profileSlug}
          />
        )}
        {isWithdrawOpen && (
          <Modal
            theme='payments'
            title={intl.formatMessage({ id: 'payments.withdraw.title' })}
            isOpen={isWithdrawOpen}
            onClose={closeWithdraw}
          >
            <Withdraw onClose={closeWithdraw} />
          </Modal>
        )}
        {isOpen && (
          <Modal
            theme='payments'
            title={intl.formatMessage({ id: 'payments.deposit.title' })}
            isOpen={isOpen}
            onClose={closeModal}
          >
            <Deposit onClose={closeModal} />
          </Modal>
        )}
      </Wrapper>
    )
  }

  if (width <= breakpoint) {
    return (
      <Wrapper>
        <Amount onClick={() => navigate(`/${intl.locale}/account/`)} />
        <DepositButton type='button' onClick={openModal} />

        {isOpen && (
          <Modal
            theme='payments'
            title={intl.formatMessage({ id: 'payments.deposit.title' })}
            isOpen={isOpen}
            onClose={closeModal}
          >
            <Deposit onClose={closeModal} />
          </Modal>
        )}
      </Wrapper>
    )
  }
}

export default UserBalance
