import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { FormattedMessage } from 'gatsby-plugin-intl'

import GameRow from '../../templates/casino/gameRow'

const SearchWrapper = styled.div`
  padding: 1rem;
`

const Search = (props) => {
  const [filteredData, setData] = useState([])

  const data = useStaticQuery(graphql`
    {
      gamesApi {
        values {
          lang
          gameNames {
            slug
            providerName
            title
            thumbnail {
              sizes {
                medium
              }
            }
          }
        }
      }
    }
  `)

  const {
    gamesApi: { values },
  } = data

  const dataExistForLocale = values.some((row) => row.lang === props.locale)
  const dataExistForPage =
    dataExistForLocale &&
    values.filter((row) => row.lang === props.locale)[0].gameNames
  const gameNames = dataExistForPage
    ? values.filter((row) => row.lang === props.locale)[0].gameNames
    : null

  useEffect(() => {
    const filteredData = gameNames.filter((game) => {
      const { title } = game
      return title.toLowerCase().includes(props.query.toLowerCase())
    })

    setData(props.query === '' ? [] : filteredData.slice(0, 5))
  }, [props.query, gameNames])

  return (
    <SearchWrapper>
      {props.query.length > 0 && filteredData.length === 0 && (
        <div>
          <FormattedMessage id='search.noResults' />
        </div>
      )}
      {filteredData.map((game) => {
        return <GameRow game={game} key={game.slug} />
      })}
    </SearchWrapper>
  )
}

export default Search
