import React from 'react'
import { FormattedNumber, IntlProvider } from 'gatsby-plugin-intl'

const FormatAmount = ({
  amount,
  currency,
  maximumFractionDigits,
  useGrouping,
}) => {
  let locale = null
  switch (currency) {
    case 'CLP':
      locale = 'es-cl'
      break
    case 'PEN':
      locale = 'es-pe'
      break
    default:
      break
  }

  if (!locale)
    return (
      <FormattedNumber
        value={amount} /* eslint-disable-next-line */
        style='currency'
        currency={currency}
        maximumFractionDigits={maximumFractionDigits || '2'}
        useGrouping={useGrouping}
      />
    )

  return (
    <IntlProvider locale={locale}>
      <FormattedNumber
        value={amount} /* eslint-disable-next-line */
        style='currency'
        currency={currency}
        maximumFractionDigits={maximumFractionDigits || '2'}
        useGrouping={useGrouping}
      />
    </IntlProvider>
  )
}

export default FormatAmount
