import * as dayjs from 'dayjs'

import { coreApi } from './base'

export const getNetDeposits = async (dateFrom, dateTo) => {
  try {
    const params = {
      created_at_from: dayjs(dateFrom).format('YYYY-MM-DDTHH:mm:ss'),
      created_at_to: dayjs(dateTo).format('YYYY-MM-DDTHH:mm:ss'),
      status: 'OK,ACCEPTED_ON_PLATFORM',
    }

    const response = await coreApi.get('/payments/totals', { params })
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getSportsbookBets = async (
  dateFrom,
  dateTo,
  page,
  size,
  extraParams = {}
) => {
  try {
    const params = {
      pageNumber: page,
      pageSize: size,
      sort: 'createdAt,desc',
      created_at_from: dayjs(dateFrom).format('YYYY-MM-DDTHH:mm:ss'),
      created_at_to: dayjs(dateTo).format('YYYY-MM-DDTHH:mm:ss'),
      ...extraParams,
    }
    const response = await coreApi.get('/sportsbook/v2/filter', { params })
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getSportsTransactions = async (
  dateFrom,
  dateTo,
  page,
  size,
  extraParams = {}
) => {
  try {
    const params = {
      pageNumber: page,
      pageSize: size,
      sort: 'createdAt,desc',
      created_at_from: dayjs(dateFrom).format('YYYY-MM-DDTHH:mm:ss'),
      created_at_to: dayjs(dateTo).format('YYYY-MM-DDTHH:mm:ss'),
      ...extraParams,
    }

    const response = await coreApi.get('/sportsbook/v2/list', { params })
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    debugger
    return { ok: false, error: error.response.data }
  }
}

export const getGameTransactions = async (dateFrom, dateTo, page, size) => {
  try {
    const params = {
      pageNumber: page,
      pageSize: size,
      sort: 'createdAt,desc',
      created_at_from: dayjs(dateFrom).format('YYYY-MM-DDTHH:mm:ss'),
      created_at_to: dayjs(dateTo).format('YYYY-MM-DDTHH:mm:ss'),
    }

    const response = await coreApi.get('/casino/filter', { params })
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getTransactions = async (
  transactionType,
  playerId,
  dateFrom,
  dateTo,
  page,
  size
) => {
  try {
    let statuses =
      'OK,DECLINED,PENDING,ACCEPTED_ON_PLATFORM,DECLINED_ON_PLATFORM,BC_MIGRATION'
    if (transactionType === 'WITHDRAWAL') {
      statuses += ',CREATED,CANCELED_BY_USER'
    }

    const params = {
      type: transactionType, // 'DEPOSIT' | 'WITHDRAWAL'
      pageNumber: page,
      pageSize: size,
      sort: 'createdAt,desc',
      user_id: playerId,
      created_at_from: dayjs(dateFrom).format('YYYY-MM-DDTHH:mm:ss'),
      created_at_to: dayjs(dateTo).format('YYYY-MM-DDTHH:mm:ss'),
      statuses: statuses,
    }

    const response = await coreApi.get('/payments', { params })
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}
