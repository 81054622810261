import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import { Link, useStaticQuery, graphql, navigate } from 'gatsby'

import storageService from '../../services/storageService'
import Btn from '../../components/common/button'
import media from '../css/media'

import Modal from '../common/modal'
import Withdraw from '../payments/withdraw/withdraw'
import Deposit from '../payments/deposit/deposit'
import useModal from '../../hooks/useModal'
import FormatAmount from '../common/formatAmount'
import { getSportsbookBets } from '../../adapters/transactions'

import icon_vault from '../../images/icon_vault.svg'
import icon_myaccount from '../../images/icon_myaccount.svg'
import ChevronRightIcon from '../../images/icon_chevron_right_white.svg'

const Header = styled.div`
  color: #fff;
  background: #000;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  width: calc(320px - 2rem);
  padding: 0 1rem;

  img {
    vertical-align: middle;
  }

  ${media.desktop`
        height: 60px;
    `};
`

const Left = styled.div`
  flex: 0 0 50%;
  width: 50%;
  color: #fff;
  text-align: left;
`

const Right = styled.div`
  flex: 0 0 50%;
  width: 50%;
  text-align: right;
  color: #fad749;
`

const MyAccountLink = styled(Link)`
  color: #fad749;
  text-decoration: none;
`

const AmountArea = styled.div`
  background-color: #343434;
  color: #fff;
  width: calc(320px - 2rem);
  padding: 1.5rem 1rem;
`

const Wrapper = styled.div`
  background-color: #343434;
  width: 320px;
`

const Row = styled.div`
  display: flex;
  gap: 20px;

  > div,
  > button {
    flex: 0 1 50%;
    line-height: 1.5;
  }
`

const TotalBalance = styled.div`
  font-size: 0.9em;
`

const Balance = styled.div`
  text-align: right;
`

const Badge = styled.div`
  background: #fad749;
  color: #000;
  position: absolute;
  left: 10px;
  top: 3.5px;
  font-size: 1em;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  line-height: 1.5;
`

const ChipsButton = styled.div`
  border-radius: 36px;
  line-height: 1.3;
  font-size: 0.9em;
  padding: 0.3rem 0.6rem 0.5rem;
  margin: 0.5rem auto;
  cursor: pointer;
  color: #fff;
  text-align: center;
  letter-spacing: 2px;
  background: #000;
  position: relative;

  > img {
    position: absolute;
    left: 10px;

    &.right-icon {
      float: right;
      margin: 0.25rem 0 0 0;
      position: initial;
    }
  }
`

const SideMenuAccount = () => {
  const user = storageService.getUser()
  const intl = useIntl()

  const [openBets, setOpenBets] = useState(0)
  const { openModal, closeModal, isOpen } = useModal()
  const {
    openModal: openWithdraw,
    closeModal: closeWithdraw,
    isOpen: isWithdrawOpen,
  } = useModal()

  const data = useStaticQuery(graphql`
    {
      menuApi {
        values {
          lang
          menuData {
            className
            link {
              url
            }
          }
        }
      }
    }
  `)

  const {
    menuApi: { values },
  } = data

  const menuExistForLocale = values.some((row) => row.lang === intl.locale)
  const menuExistForPage =
    menuExistForLocale &&
    values.filter((row) => row.lang === intl.locale)[0].menuData
  const menuData = menuExistForPage
    ? values.filter((row) => row.lang === intl.locale)[0].menuData
    : null

  const cashbackCta = menuData.some((m) => m.className === 'cta cashback')
    ? menuData.filter((m) => m.className === 'cta cashback')[0]
    : null

  const getOpenBets = () => {
    const date = new Date()
    date.setDate(date.getDate() - 7)

    getSportsbookBets(date, new Date(), 0, 50, {
      bet_status: 'C',
    }).then((res) => {
      setOpenBets(res.data.data.numberOfElements)
    })
  }

  useEffect(() => {
    getOpenBets()
    setInterval(getOpenBets, 60000)

    return function () {
      clearInterval(getOpenBets)
    }
  }, [])

  return (
    <Wrapper>
      <Header>
        <Left onClick={() => navigate(`/${intl.locale}/account/`)}>
          <img
            src={icon_myaccount}
            alt='My account Icon'
            width='30'
            height='30'
          />
          {user?.first_name}
        </Left>
        <Right>
          <MyAccountLink to={`/${intl.locale}/account/`}>
            <FormattedMessage id='navigation.account' />
          </MyAccountLink>
        </Right>
      </Header>
      <AmountArea>
        <Row>
          <TotalBalance>
            <FormattedMessage id='navigation.totalBalance' />
          </TotalBalance>
          <Balance>
            <FormatAmount
              amount={user?.wallet?.amount}
              currency={user?.wallet?.currency?.short_code}
            />
          </Balance>
        </Row>

        <ChipsButton
          onClick={() => navigate(`/${intl.locale}/account/transactions/`)}
        >
          <Badge>{openBets}</Badge>
          <FormattedMessage id='navigation.myBets' />
          <img
            className='right-icon'
            src={ChevronRightIcon}
            alt='Right'
            width={10}
          />
        </ChipsButton>
        <ChipsButton onClick={() => navigate(`/${intl.locale}/old-bets/`)}>
          <FormattedMessage id='navigation.oldBets' />
          <img
            className='right-icon'
            src={ChevronRightIcon}
            alt='Right'
            width={10}
          />
        </ChipsButton>
        <ChipsButton
          onClick={() => navigate(`/${intl.locale}${cashbackCta.link.url}`)}
        >
          <img src={icon_vault} alt='Vault Icon' width={25} height={25} />
          <FormattedMessage id='navigation.vault' />
          <img
            className='right-icon'
            src={ChevronRightIcon}
            alt='Right'
            width={10}
          />
        </ChipsButton>

        <Row>
          <Btn
            text={intl.formatMessage({ id: 'navigation.deposit' })}
            bgcolor='#31c27c'
            padding='.32rem 1rem'
            bordercolor='#31c27c'
            fontSize='.85em'
            onClick={openModal}
          />
          <Btn
            text={intl.formatMessage({ id: 'navigation.withdraw' })}
            bgcolor='#000'
            padding='.32rem 1rem'
            bordercolor='#000'
            fontSize='.85em'
            onClick={openWithdraw}
          />
        </Row>
      </AmountArea>

      {isWithdrawOpen && (
        <Modal
          theme='payments'
          title={intl.formatMessage({ id: 'payments.withdraw.title' })}
          isOpen={isWithdrawOpen}
          onClose={closeWithdraw}
        >
          <Withdraw onClose={closeWithdraw} />
        </Modal>
      )}

      {isOpen && (
        <Modal
          theme='payments'
          title={intl.formatMessage({ id: 'payments.deposit.title' })}
          isOpen={isOpen}
          onClose={closeModal}
        >
          <Deposit onClose={closeModal} />
        </Modal>
      )}
    </Wrapper>
  )
}

export default SideMenuAccount
