import { useState, useRef, useEffect } from 'react'

const PADDINGS = 0
const MARGINS = 10

const useSliding = (elementWidth, countElements, elementsInViewport) => {
  const containerRef = useRef(null)
  const [containerWidth, setContainerWidth] = useState(0)
  const [distance, setDistance] = useState(0)
  const [totalInViewport, setTotalInViewport] = useState(0)
  const [viewed, setViewed] = useState(0)

  useEffect(() => {
    const containerWidth = containerRef.current.clientWidth - PADDINGS

    setContainerWidth(containerWidth)
    setTotalInViewport(Math.round(containerWidth / elementWidth))
  }, [elementWidth])

  const handlePrev = () => {
    setViewed(viewed - totalInViewport)
    setDistance(distance + containerWidth + MARGINS)
  }

  const handleNext = () => {
    setViewed(viewed + totalInViewport)
    setDistance(distance - containerWidth - MARGINS)
  }

  const slideProps = {
    style: { transform: `translate3d(${distance}px, 0, 0)` },
  }

  const hasPrev = distance < 0
  const hasNext = viewed + totalInViewport < countElements / elementsInViewport

  return { handlePrev, handleNext, slideProps, containerRef, hasPrev, hasNext }
}

export default useSliding
