import React from 'react'
import { toast as baseToast } from 'react-toastify'
import ToastMessage from './toastMessage'

const toastInternal = (status, message, options, statusOverrides) => {
  const params = {
    className: status,
    autoClose: 30000,
    hideProgressBar: false,
    pauseOnVisibilityChange: false,
    draggable: false,
    pauseOnHover: false,
    position: 'top-center',
    ...statusOverrides,
    ...options,
  }

  return baseToast(
    <ToastMessage message={message} icon={status} options={options} />,
    params
  )
}

class toast {
  static success(message, options) {
    return toastInternal('success', message, options)
  }

  static info(message, options) {
    return toastInternal('info', message, options)
  }

  static warn(message, options) {
    return toastInternal('warn', message, options)
  }

  static error(message, options) {
    return toastInternal('error', message, options)
  }

  static dismiss(id) {
    return baseToast.dismiss(id)
  }
}

export default toast
