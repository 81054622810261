import React from 'react'
import styled from 'styled-components'

const ErrorMsg = styled.div`
  color: #da0000;
  margin: 1rem 0 0 0;
`

const Error = (props) => <ErrorMsg {...props}>{props.text}</ErrorMsg>

export default Error
