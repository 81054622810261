import { useState } from 'react'

const useModal = () => {
  const [isOpen, setIsOpen] = useState()

  const openModal = () => {
    setIsOpen(!isOpen)
  }

  const closeModal = () => {
    setIsOpen(!isOpen)
  }

  return {
    openModal,
    closeModal,
    isOpen,
  }
}

export default useModal
