import React from 'react'

const ModalContext = React.createContext()

const ActionTypes = {
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
}

const modalReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.OPEN_MODAL: {
      return {
        ...state,
        modalStatus: true,
        type: action.payload.type,
        title: action.payload.title,
        data: action.payload.data,
      }
    }
    case ActionTypes.CLOSE_MODAL: {
      return {
        ...state,
        modalStatus: initialState.status,
        type: initialState.type,
        title: initialState.title,
        data: initialState.data,
      }
    }

    default:
      throw new Error(`Action is not supported: ${action.type}`)
  }
}

const initialState = {
  modalStatus: false,
  title: '',
  type: null,
  data: null,
}

export const ModalProvider = (props) => {
  const [state, dispatch] = React.useReducer(modalReducer, initialState)

  const value = React.useMemo(() => ({ state, dispatch }), [state])

  return <ModalContext.Provider value={value} {...props} />
}

export const useModalContext = () => {
  const context = React.useContext(ModalContext)

  if (!context) {
    throw new Error('useModalContext must be used inside a ModalProvider')
  }

  const { state, dispatch } = context
  const { modalStatus, type, data, title } = state

  const open = (data) => {
    dispatch({ type: ActionTypes.OPEN_MODAL, payload: data })
  }

  const close = (_) => {
    dispatch({ type: ActionTypes.CLOSE_MODAL })
  }

  return { open, close, modalStatus, type, data, title }
}
