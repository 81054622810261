import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import storageService from '../../services/storageService'

import DesktopNav from './desktopNav'
import MobileNav from './mobileNav'
import { AuthContext } from '../../context/authProvider'
import toast from '../common/toast'

import Modal from '../common/modal'
import Notification from '../common/notification'
import {
  getUserNotification,
  setVisualizeNotification,
} from '../../adapters/user'

const HeaderWrapper = styled.header`
  background-color: #000000;
  position: sticky;
  top: 0;
  z-index: 500;
`

const Nav = styled.nav`
  color: #ffffff;
  padding: 0 1rem;
`

const Desktop = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;
  height: 60px;

  @media (min-width: 1600px) {
    display: flex;
  }
`

const Mobile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;

  @media (min-width: 1600px) {
    display: none;
  }
`

let activationWarning = false

const Header = ({ locale }) => {
  const data = useStaticQuery(graphql`
    {
      menuApi {
        values {
          lang
          menuData {
            name
            type
            text
            className
            position
            section
            show_icon
            image {
              alt
              url
              width
              height
            }
            link_logged_in {
              url
            }
            link {
              url
              title
              target
            }
          }
        }
      }
    }
  `)

  const intl = useIntl()
  const { user } = useContext(AuthContext)

  const [notificationModal, setNotificationModal] = useState(false)
  const [notificationContent, setNotificationContent] = useState({})

  useEffect(() => {
    const checkForNotification = () => {
      const checkedNotification = storageService.getValue('notification')
      const token = storageService.getAccessToken()
      if (!checkedNotification && user && token) {
        getUserNotification().then((response) => {
          storageService.setValue('notification', 'checked')
          if (response.ok && response.data) {
            setNotificationContent(response.data.data)
            setNotificationModal(true)
          }
        })
      }
    }

    setTimeout(() => {
      checkForNotification(true)
    }, 5000)
  }, [user])

  const handleCloseModal = () => {
    setNotificationModal(false)
    setVisualizeNotification()
  }

  const {
    menuApi: { values },
  } = data

  const dataExistForLocale = values.some((row) => row.lang === locale)
  const dataExistForPage =
    dataExistForLocale &&
    values.filter((row) => row.lang === locale)[0].menuData
  const menuData = dataExistForPage
    ? values.filter((row) => row.lang === locale)[0].menuData
    : null

  if (menuData.length === 0) return null

  if (
    user?.user_status?.name?.toLowerCase() === 'pending' &&
    !activationWarning
  ) {
    activationWarning = true

    toast.error(intl.formatMessage({ id: 'account.not-verified' }), {
      autoClose: false,
      position: 'bottom-left',
    })
  }

  return (
    <HeaderWrapper>
      <Nav role='navigation'>
        <Desktop>
          <DesktopNav locale={locale} data={menuData} />
        </Desktop>
        <Mobile>
          <MobileNav locale={locale} data={menuData} />
        </Mobile>
      </Nav>
      {notificationModal && (
        <Modal
          title={notificationContent.title}
          isOpen={notificationModal}
          onClose={handleCloseModal}
        >
          <Notification
            message={notificationContent.message}
            onBtnClick={handleCloseModal}
          />
        </Modal>
      )}
    </HeaderWrapper>
  )
}

export default Header
