import React from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'

const InovapayWallet = ({ register, errors, touchedFields }) => {
  return (
    <>
      <div>
        <label htmlFor='userLogin'>
          <FormattedMessage id='payments.userLogin' />
        </label>
        <input
          name='userLogin'
          className={`${errors.userLogin ? 'invalid' : ''} ${
            !errors.userLogin && touchedFields.userLogin ? 'valid' : ''
          }`}
          {...register('userLogin', {
            required: true,
          })}
        />
      </div>
    </>
  )
}

export default InovapayWallet
